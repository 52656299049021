import React from "react";

// Redux
import { connect } from "react-redux";

// import Material components
import { Typography } from "@material-ui/core";

// Import components
import SortingSelecting from "./SortingSelecting";
import CustomToolbar from "./CustomToolbar";

// Actions
import {
  cleanProductSearch, reloadPhotoTabProduct
} from "../../../redux/actions/product/products.actions";
import { getProductFiltersForRequest, getProductFiltersForUi } from "./ProductSearchFilters";
import { canReadApplication } from "../../../helpers/CredentialsHelper";
import { applications } from "../../../constants/app.constants";
import { LoadingLogo } from "../../shared/Loading";
import Pagination from "material-ui-flat-pagination";
import { loadFiltersFromStorage } from "../../../helpers/SearchHelper";
import { GetLatestOfferValidToDateWherePhotoIsUsed } from "../../shared/SharedFunctions";
import { showErrorNotification, sortArrayAlphabetically, validateSearchString } from "../../../helpers/UtilHelper";
import { Helmet } from "react-helmet";
import SearchBarV2 from "../../shared/components/searchBarV2/SearchBarV2";
import { fetchCustomers } from "../../../redux/actions/utils/customers.utils.actions";
import { searchProducts } from "./Requests";
import DialogPreReplacePhoto from "../photos/dialogs/DialogPreReplacePhoto";
import DialogReplacePhoto from "../photos/dialogs/DialogReplacePhoto";
import store from "../../../redux/store";
import DialogEditProduct from "./dialogs/DialogEditProduct";
import DialogDeletePhotoV2 from "../photos/dialogs/DialogDeletePhotoV2";
import { IsFeatureEnabled, featureNames } from "../../../helpers/FeaturesHelper";
import { getProductTags } from "../../sections/fototracker/SharedRequests";

import SearchBarWithAutoSuggest from "../../shared/components/searchBarTags/searchBar/SearchBarWithAutoSuggest";

import Resources from "../../../Resources.json"
import {GetResource} from "../../../helpers/ResourceHelper" 

class Produktstyring extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      storageKeyText: "search_text_product",
      storageKeyFilters: "search_filters_product",
      searchText: "",
      searchFilters: [], // options to filter on while making search request
      searchResult: null,
      searchRowOffset: 0,
      rowsPerPage: 12,

      performedFirstSearch: false,
      dialogEditOpen: false,
      openDialogDeletePhoto: false,
      deletePhotos: [],
      dateDeletePhoto: undefined,

      selectedMotivId: undefined,
      dialogWherePhotoUsed: false,
      dialogReplacePhoto: false,

      productTagsAutoSuggest: [],      
      tagsSearchArray: []
    };
  }

  async componentDidMount() {
    if (canReadApplication(applications.PRODUKTER)) {
      await this.props.dispatch(fetchCustomers());
      // load search request arguments from cache
      let filters = getProductFiltersForUi(this.props.utilsReducer.customers);
      let searchText = "";
      try {
        filters = loadFiltersFromStorage(filters, this.state.storageKeyFilters);
        searchText = localStorage.getItem(this.state.storageKeyText);
      } catch { }

      this.initializeTagsAutoComplete();

      this.setState({
        searchText: searchText,
        searchFilters: filters
      },
        () => this.performSearch()
      );

    }
  }

  async initializeTagsAutoComplete(){
    let productTags = await getProductTags();
    productTags = sortArrayAlphabetically(productTags);
    this.setState({
      productTagsAutoSuggest:productTags
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.state.loading &&
      (prevState.searchRowOffset !== this.state.searchRowOffset || prevState.searchFilters !== this.state.searchFilters)) {
      this.performSearch();
    }
  }

  handleProductCreated = (productNumber) => {
    this.initializeTagsAutoComplete();
    this.setState({ searchText: productNumber, searchRowOffset: 0 }, () => {
      this.performSearch();
    });
  };

  keyPress = (e) => {
    if (e.keyCode === 13) {
      this.performSearch();
    }
  };

  async performSearch() {
    // validate search text. First line fix
    const textIsValid = validateSearchString(this.state.searchText);
    if (!textIsValid) {
      showErrorNotification("Ugyldig søgetekst");
      this.setState({
        searchText: ""
      });

      return;
    }
    try {
      this.setState({ loading: true, performedFirstSearch: true });
      // construct search request
      const searchRequest = {
        searchText: this.state.searchText,
        filters: getProductFiltersForRequest(this.state.searchFilters),
        startRow: this.state.searchRowOffset,
        numberOfRows: this.state.rowsPerPage,
        tags:this.state.tagsSearchArray
      };

      const searchResult = await searchProducts(searchRequest);
      this.setState({ loading: false, searchResult: searchResult });
    } catch (e) {
      showErrorNotification(`Søgning fejlede. ${e.message}`);
      this.setState({ loading: false });
    }

    // cache search request arguments
    localStorage.setItem(this.state.storageKeyText, this.state.searchText);
    localStorage.setItem(this.state.storageKeyFilters, JSON.stringify(this.state.searchFilters));
  };

  componentWillUnmount() {
    this.props.dispatch(cleanProductSearch());
  }

  closeEditDialog = () => {
    this.initializeTagsAutoComplete();
    this.setState({
      dialogEditOpen: false,
    });
  };

  openEditDialog = () => {
    this.setState({
      dialogEditOpen: true,
    });
  };

  handleDelete = async (id) => {
    if (!id) {
      return;
    }

    this.setState({
      dataLoading: true,
      openDialogDeletePhoto: true,
      deletePhotos: [id]
    });

    const dateValidTo = await GetLatestOfferValidToDateWherePhotoIsUsed(id);

    this.setState({
      dataLoading: false,
      dateDeletePhoto: dateValidTo
    })
  };

  handleCloseDeletePhotoDialog = () => {
    this.setState({
      openDialogDeletePhoto: false,
      deletePhotos: [],
      dateDeletePhoto: undefined
    })
  }

  handleNewSearch() {
    this.setState({ searchRowOffset: 0 }, () => this.performSearch())
  }

  setSelectedMotivId = (motivId) => {
    this.setState({
      selectedMotivId: motivId,
      dialogWherePhotoUsed: true
    })
  }

  handleChangeTagsText = value => {
    this.setState({ 
      tagsSearchArray: value,
      searchRowOffset: 0
     }, () => this.performSearch());
  };
  handleCloseDialogWherePhotoUsed = () => {
    this.setState({
      selectedMotivId: undefined,
      dialogWherePhotoUsed: false
    })
  }
  confirmReplacePhoto = () => {
    this.setState({
      dialogReplacePhoto: true,
      dialogWherePhotoUsed: false
    })
  }
  closeDialogReplacePhoto = () => {
    this.setState({
      dialogReplacePhoto: false,

    });
  }

  refreshPhotoTab = () => {
    setTimeout(async () => {
      await store.dispatch(reloadPhotoTabProduct(true), 2000);
      store.dispatch(reloadPhotoTabProduct(false));
    });
  };

  render() {
    if (!IsFeatureEnabled(featureNames.Products_Enabled) || !canReadApplication(applications.PRODUKTER)) {
      return (
        <Typography variant="subtitle1" align="center">
          Du har ikke adgang til denne side. Kontakt support
          (mbsupport@republica.dk), hvis dette er en fejl.
        </Typography>
      );
    }


    let searchState;
    if (!this.state.performedFirstSearch)
      searchState = undefined;
    else if (this.state.loading)
      searchState = <LoadingLogo />;
    else if (this.state.searchResult.rows.length === 0) {
      searchState = (
        <Typography variant="subtitle1" align="center">
          Ingen produkter fundet...
        </Typography>
      );
    }
    else {
      searchState = (<>
        <SortingSelecting
          openEditDialog={this.openEditDialog.bind(this)}
          refreshSearch={() => this.performSearch()}
          dataRows={this.state.searchResult.rows}
        />
        {this.state.searchResult.totalRows === this.state.searchResult.numberOfRows ? (
          undefined
        ) : (
          <Pagination
            limit={this.state.rowsPerPage}
            offset={this.state.searchRowOffset}
            total={Math.min(this.state.searchResult.totalRows, 100 * this.state.rowsPerPage)}
            onClick={(event, offset) => this.setState({ searchRowOffset: offset })}
            centerRipple={true}
            style={{ textAlign: "center" }}
          />
        )}
      </>
      );
    }


    return (
      <>
        <Helmet>
          <title>{GetResource(Resources.Products_Helmet_Title)}</title>
        </Helmet>
        <Typography variant="h4">{GetResource(Resources.Products_Title)}</Typography>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: IsFeatureEnabled(featureNames.Products_Create_Enabled) ? 'auto 40% 50px' : 'auto 40%',
            columnGap: 10
          }}
        >

          <SearchBarV2
            onSearch={() => this.handleNewSearch()}
            onSearchTextChanged={text => this.setState({ searchText: text })}
            searchText={this.state.searchText}
            searchFilters={this.state.searchFilters}
            onSearchFiltersChange={filters => this.setState({ searchFilters: filters })}
          />
          <SearchBarWithAutoSuggest
            autoSuggestTags={this.state.productTagsAutoSuggest}
            queryArray={this.state.tagsSearchArray}
            handleChangeTagsSearchArray={(value) => this.handleChangeTagsText(value)}
          />
          {IsFeatureEnabled(featureNames.Products_Create_Enabled) ?
            <CustomToolbar onProductCreated={this.handleProductCreated.bind(this)} /> : undefined
          }
        </div>
        {searchState}


        <DialogEditProduct
          edit={this.state.dialogEditOpen}
          closeEditDialog={this.closeEditDialog.bind(this)}
          deletePhoto={(productPhotoId) => this.handleDelete(productPhotoId)}
          onEditImage={(photoId) => this.setSelectedMotivId(photoId)}
        />

        {IsFeatureEnabled(featureNames.Products_Delete_Enabled) && this.state.openDialogDeletePhoto && this.state.deletePhotos ?
          (<DialogDeletePhotoV2
            performSearch={this.performSearch.bind(this)}
            dateDeletePhotoAllowed={this.state.dateDeletePhoto}
            dataLoading={this.state.dataLoading}
            photoIds={this.state.deletePhotos}
            handleClose={() => this.handleCloseDeletePhotoDialog()}
          />) : undefined
        }
        {this.state.dialogWherePhotoUsed && this.state.selectedMotivId ?
          (<DialogPreReplacePhoto
            open={true}
            motiveId={this.state.selectedMotivId}
            handleClose={this.handleCloseDialogWherePhotoUsed}
            renderActionButtons={true}
            confirmReplacePhoto={this.confirmReplacePhoto}
            messageReplacePhoto="Du er ved at erstatte motiv-ID med følgende billede."
            messageConfirmReplacePhoto="Vil du fortsat ændre billedet?"
          />) : undefined
        }
        {this.state.dialogReplacePhoto ?
          <DialogReplacePhoto
            motivId={this.state.selectedMotivId}
            handleCancel={this.closeDialogReplacePhoto}
            performSearch={() => this.performSearch()}
            storageKey={this.state.storageKey}
            callback={() => this.refreshPhotoTab()}
          /> : undefined
        }
      </>
    );
  }
}

export default connect((state) => state)(Produktstyring);
