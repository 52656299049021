import React, { Component } from "react";
import { connect } from "react-redux";
import { Typography } from "@material-ui/core";
import SortingSelecting from "./SortingSelecting";
import DialogEditOffer from "./Dialogs/DialogEditOffer";
import {
  cleanOffer
} from "../../../redux/actions/offer/offers.actions";
import { canReadApplication } from "../../../helpers/CredentialsHelper";
import { applications } from "../../../constants/app.constants";
import { searchOffers } from "./Requests";
import SearchBarV2 from "../../shared/components/searchBarV2/SearchBarV2";
import { showErrorNotification, stringIsEmpty } from "../../../helpers/UtilHelper";
import { loadFiltersFromStorage } from "../../../helpers/SearchHelper";
import { fetchCustomers } from "../../../redux/actions/utils/customers.utils.actions";
import Pagination from "material-ui-flat-pagination";
import { LoadingLogo } from "../../shared/Loading";
import { getOfferFiltersForRequest, getOfferFiltersForUi } from "./OfferSearchFilters";
import { Helmet } from "react-helmet";
import { getOffersTags } from "../../shared/Requests";
import CustomSearchBar from "../../shared/components/customSearchBar/CustomSearchBar";
import SearchBarWithAutoSuggest from "../../shared/components/searchBarTags/searchBar/SearchBarWithAutoSuggest";

import Resources from "../../../Resources.json"
import {GetResource} from "../../../helpers/ResourceHelper" 
import { IsFeatureEnabled, featureNames } from "../../../helpers/FeaturesHelper";

class Tilbudsstyring extends Component {
  constructor(props) {
    super(props);

    this.state = {
      storageKeyText: "search_text_offer",
      storageKeyFilters: "search_filters_offer",
      storageKeyPageFilter: "search_page_filter_offer",
      searchText: "",
      searchFilters: [], // options to filter on while making search request
      pagesFilter: [],
      sectionId: applications.TILBUDSSTYRING,
      searchResult: null,
      searchRowOffset: 0,
      rowsPerPage: 12,
      publicationId: undefined,
      publicationName: undefined,
      loading: true,
      offersTags: [],
      tagsSearchString: []
    };
  }

  async componentDidMount() {
    if (canReadApplication(applications.TILBUDSSTYRING)) {
      const requestFromPublication = this.props.location.state;
      await this.props.dispatch(fetchCustomers());

      let jobNumber = null;
      if (requestFromPublication) {
        jobNumber = requestFromPublication.jobNumber.toString();
        this.setState({
          publicationId: requestFromPublication.publicationId,
          publicationName: requestFromPublication.publicationName
        });

        let locationState = { ...requestFromPublication };
        delete locationState.publicationId;
        delete locationState.publicationName;
        delete locationState.jobNumber;
        this.props.history.replace(locationState);
      }

      let filters = getOfferFiltersForUi(this.props.utilsReducer.customers);
      let searchText = "";
      let pagesFilter = [];
      if (jobNumber) {
        // override search text as jobNumber
        searchText = jobNumber
      } else {
        // load search request arguments from cache
        try {
          filters = loadFiltersFromStorage(filters, this.state.storageKeyFilters);
          searchText = localStorage.getItem(this.state.storageKeyText);
          pagesFilter = localStorage.getItem(this.state.storageKeyPageFilter);
        } catch { }
      }
      this.loadOffersTags()

      this.setState({
        searchText: searchText,
        searchFilters: filters,
        pagesFilter: pagesFilter || []
      },
        () => this.performSearch()
      );
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.state.loading &&
      (prevState.searchRowOffset !== this.state.searchRowOffset || prevState.searchFilters !== this.state.searchFilters)) {
      this.performSearch();
    }
  }


  async performSearch() {
    try {
      this.setState({ loading: true });
      // construct search request
      const filters = getOfferFiltersForRequest(this.state.searchFilters, this.state.pagesFilter);
      const searchRequest = {
        searchText: this.state.searchText,
        filters: { ...filters, ...{ tags: this.state.tagsSearchString } },
        startRow: this.state.searchRowOffset,
        numberOfRows: this.state.rowsPerPage
      };

      const searchResult = await searchOffers(searchRequest);
      this.setState({ loading: false, searchResult: searchResult });
    } catch (e) {
      showErrorNotification(`Søgning fejlede. ${e.message}`);
      this.setState({ loading: false });
    }

    // cache search request arguments
    localStorage.setItem(this.state.storageKeyText, this.state.searchText);
    localStorage.setItem(this.state.storageKeyPageFilter, this.state.pagesFilter);
    localStorage.setItem(this.state.storageKeyFilters, JSON.stringify(this.state.searchFilters));
  };

  loadOffersTags = async () => {
    const tags = await getOffersTags();
    this.setState({ offersTags: tags });
  }

  componentWillUnmount() {
    this.props.dispatch(cleanOffer());
  }

  handleNewSearch() {
    this.setState({ searchRowOffset: 0 }, () => this.performSearch())
  }

  handleFiltersChanged = (filters, pages) => {
    this.setState({
      searchFilters: filters,
      pagesFilter: pages
    })
  }

  handleChangeTagsText = event => {
    this.setState({ 
      tagsSearchString: event 
    }, () => this.performSearch());
  };

  keyPress = e => {
    if (!this.state.loading) {
      if (e.keyCode === 13) {
        this.handleNewSearch();
      }
    }
  };

  handleTagsReload = event => {
    this.setState({offersTags: event})
  }

  render() {
    if (!IsFeatureEnabled(featureNames.Offers_Enabled) || !canReadApplication(applications.TILBUDSSTYRING)) {
      return (
        <Typography variant="subtitle1" align="center">
          Du har ikke adgang til denne side. Kontakt support
          (mbsupport@republica.dk), hvis dette er en fejl.
        </Typography>
      )
    }

    let searchState = null;
    if (this.state.loading) {
      searchState = <LoadingLogo />;
    } else if (!this.state.searchResult || this.state.searchResult.rows.length === 0) {
      searchState = <Typography variant="subtitle1" align="center">{GetResource(Resources.Offers_Search_No_Offers_Found)}</Typography>;
    } else {
      searchState = <>
        <SortingSelecting
          loading={this.state.loading}
          dataRows={this.state.searchResult.rows}
          loadOffersTags={() => this.loadOffersTags()}
        />
        {this.state.searchResult.totalRows === this.state.searchResult.numberOfRows ? (
          undefined
        ) : (
          <Pagination
            limit={this.state.rowsPerPage}
            offset={this.state.searchRowOffset}
            total={Math.min(this.state.searchResult.totalRows, 100 * this.state.rowsPerPage)}
            onClick={(event, offset) => this.setState({ searchRowOffset: offset })}
            centerRipple={true}
            style={{ textAlign: "center" }}
          />
        )}
      </>
    }

    return (
      <>

        <Helmet>
          <title>{GetResource(Resources.Offers_Helmet_Title)}</title>
        </Helmet>
        <Typography variant="h4">{GetResource(Resources.Offers_Title)}</Typography>

        <div
          style={{
            display: "grid",
            gridTemplateColumns: "auto 40%",
            columnGap: 10
          }}
        >
          <SearchBarV2
            onSearch={() => this.handleNewSearch()}
            onSearchTextChanged={text => this.setState({ searchText: text })}
            onSearchFiltersChange={(filters, pages) => this.handleFiltersChanged(filters, pages)}
            searchFilters={this.state.searchFilters}
            searchText={this.state.searchText}
            searchPagesFilter={this.state.pagesFilter}
            fromTilbud={true} // a temporary solution to show filter on page number in specific components
          />

          <SearchBarWithAutoSuggest
           autoSuggestTags={this.state.offersTags}
           queryArray={this.state.tagsSearchString}
           handleChangeTagsSearchArray={(event) => this.handleChangeTagsText(event)}
          />

        </div>
        <Typography variant="h6">{this.state.publicationName}</Typography>
        {searchState}
        {
          this.props.offersReducer.editDialog ?
            <DialogEditOffer
              offerId={this.props.offersReducer.id}
              publicationName={this.props.offersReducer.publicationName}
              performSearch={() => this.performSearch()}
              offersTags={this.state.offersTags}
              offersTagsReload={event=>this.handleTagsReload(event)}
            /> : undefined
        }
      </>
    );
  }
}

export default connect(state => state)(Tilbudsstyring);
