import { Typography } from "@material-ui/core";

import React, { Component } from "react";
import { connect } from "react-redux";

import { canReadApplication } from "../../../helpers/CredentialsHelper";
import { applications } from "../../../constants/app.constants";
import { LoadingLogo } from "../../shared/Loading";
import DataTable from "./DataTable";
import { Helmet } from "react-helmet";
import { getPublicationFiltersForRequest, getPublicationFiltersForUi } from "./PublicationSearchFilters";
import { loadFiltersFromStorage } from "../../../helpers/SearchHelper";
import { getPublicationTags, searchPublications, getCorrectionChanges } from "./Requests";
import { getCorrections } from "./Requests";
import SearchBarV2 from "../../shared/components/searchBarV2/SearchBarV2";
import { fetchCustomers } from "../../../redux/actions/utils/customers.utils.actions";
import { showErrorNotification, stringIsEmpty, getLongDate } from "../../../helpers/UtilHelper";
import Pagination from "material-ui-flat-pagination";
import { getSinglePublicationFromSolr } from "../tilbudsstyring/Requests";
import CustomSearchBar from "../../shared/components/customSearchBar/CustomSearchBar";
import DialogPreviewPublication from "./dialogs/DialogPreviewPublication/DialogPreviewPublication";
import { updateCorrectionsView } from "./logic/BusinessLogic";
import SearchBarWithAutoSuggest from "../../shared/components/searchBarTags/searchBar/SearchBarWithAutoSuggest";
import Resources from "../../../Resources.json";
import {GetResource} from "../../../helpers/ResourceHelper";
import { IsFeatureEnabled, featureNames } from "../../../helpers/FeaturesHelper";
import { openDialogAccept } from "../../shared/dialogs/DialogConfirm";

class Publikationstyring extends Component {

  constructor(props) {
    super(props);

    this.state = {
      storageKeyText: "search_text_publications",
      storageKeyFilters: "search_filters_publications",
      searchText: "",
      searchFilters: [], // options to filter on while making search request
      searchResult: null,
      corrections: null,
      searchRowOffset: 0,
      rowsPerPage: 10,
      loading: true,
      publicationPreviewId: undefined,
      publicationPreviewDialog: false,
      autoSuggestTags: [],
      tagsSearchArray: [],
    };
  }

  componentDidMount() {
    this.handleInitialSearch().catch(e => {
      throw e
    });
  }
  
  async handleInitialSearch() {
    const _autoSuggestTags = await getPublicationTags();
    if (canReadApplication(applications.PUBLICATION)) {
      
      if (this.props.skipFetchingCustomers !== true) {
        await this.props.dispatch(fetchCustomers());
      }
      
      let filters = getPublicationFiltersForUi(this.props.utilsReducer.customers);
      let searchText = "";
      // load search request arguments from cache
      try {
        filters = loadFiltersFromStorage(filters, this.state.storageKeyFilters);
        searchText = localStorage.getItem(this.state.storageKeyText) ?? this.props.storageKeyTextMock;
      } catch { }

      this.setState({
        searchText: searchText,
        searchFilters: filters,
        autoSuggestTags: _autoSuggestTags
      }, () =>
        this.performSearch().catch(e => {
          throw e;
        })
      );
    }
  }

  fetchAutoSuggestTags = async () => {
    const _autoSuggestTags = await getPublicationTags();
    this.setState({
      autoSuggestTags: _autoSuggestTags
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.state.loading &&
      (prevState.searchRowOffset !== this.state.searchRowOffset || prevState.searchFilters !== this.state.searchFilters)) {
      this.performSearch();
    }
  }


  async performSearch() {
    try {
      this.setState({ loading: true });
      // construct search request
      const filters = getPublicationFiltersForRequest(this.state.searchFilters, false, this.props.utilsReducer.customers);
      const tags = this.state.tagsSearchArray;
      const searchRequest = {
        searchText: this.state.searchText,
        filters: { ...filters, ...{ tags: tags } },
        startRow: this.state.searchRowOffset,
        numberOfRows: this.state.rowsPerPage
      };

      const searchResult = await searchPublications(searchRequest);

      const publicationIds = searchResult.rows.map(p=>p.id);

      const corrections = await getCorrections(publicationIds);

      this.setState({ loading: false, searchResult: searchResult, corrections:corrections });
    } catch (e) {
      showErrorNotification(`Søgning fejlede. ${e.message}`);
      this.setState({ loading: false });
    }

    // cache search request arguments
    localStorage.setItem(this.state.storageKeyText, this.state.searchText);
    localStorage.setItem(this.state.storageKeyFilters, JSON.stringify(this.state.searchFilters));
  };

  handleNewSearch() {
    this.setState({ searchRowOffset: 0 }, () => this.performSearch())
  }

  keyPress = e => {
    if (!this.state.loading) {
      if (e.keyCode === 13) {
        this.handleNewSearch();
      }
    }
  };

  updateDataTable = (publicationId) => {
    setTimeout(async () => {
      const publication = await getSinglePublicationFromSolr(publicationId);
      const _corrections = await updateCorrectionsView(publicationId, this.state.corrections);
      const stateSearchResult = { ...this.state.searchResult };
      const publications = stateSearchResult.rows;
      const updatePhotoOrder = publications.findIndex(x => x.id === publicationId);
      if (updatePhotoOrder !== undefined) {
        publications[updatePhotoOrder] = publication;
        this.setState({
          searchResult: stateSearchResult,
          corrections: _corrections
        });
      }
    }, 2000);
  }

  handleChangeTagsText = value => {
    this.setState({ 
      tagsSearchArray: value,
      searchRowOffset: 0
     }, () => this.performSearch());
  };

  handleOpenPreviewPublicationDialog = (pubId, pages) => {
    this.setState({
      publicationPreviewId: pubId,
      publicationPages: pages,
      publicationPreviewDialog: true
    });
  }

  handleClosePreviewPublicationDialog = () => {
    this.setState({
      publicationPreviewId: undefined,
      publicationPages: undefined,
      publicationPreviewDialog: false
    });
  }
  
  async showCorrectionCounts(publicationName, publicationId) {
    const response = await getCorrectionChanges(publicationId);

    var changeHeader = "Ændringer for " + publicationName;

    if (!response || response.length === 0 || response.length > 2) {
      openDialogAccept(
        changeHeader, 
        ["Der opstod en fejl, kontakt Support (mbsupport@republica.dk)"]
      );
      return;
    } 
    if(response.length == 1){
      openDialogAccept(
        changeHeader, 
        [
          "Seneste ændringer: "+getLongDate(response[0].dateTime)
        ]);  
    }
    else{

      //one if the 2 values have corrections, the other one does not, figure out which is which
      var noChangeIndex = response[0].correctionCount == 0 ? 0 : 1;
      var noChangeDate = response[noChangeIndex].dateTime;
      var changeDate = response[noChangeIndex == 0 ? 1 : 0].dateTime;

      openDialogAccept(
        changeHeader, 
        [
          "Data modtaget "+ getLongDate(noChangeDate) + " har ingen ændringer.",
          "Seneste ændringer " + getLongDate(changeDate)
        ]);
    }

  }

  render() {
    if (!IsFeatureEnabled(featureNames.Publications_Enabled) || !canReadApplication(applications.PUBLICATION)) {
      return (
        <Typography variant="subtitle1" align="center">
          Du har ikke adgang til denne side. Kontakt support
          (mbsupport@republica.dk), hvis dette er en fejl.
        </Typography>
      );
    }

    let searchState = null;
    if (this.state.loading) {
      searchState = <LoadingLogo />;
    } else if (!this.state.searchResult || this.state.searchResult.rows.length === 0) {
      searchState = <Typography variant="subtitle1" align="center">Ingen publikationer fundet...</Typography>;
    } else {
      searchState = <>
        <DataTable
          corrections={this.state.corrections}
          onSearch={() => this.performSearch()}
          dataRows={this.state.searchResult.rows}
          updateDataTable={(id) => this.updateDataTable(id)}
          handleOpenPreviewPublicationDialog={(pubId, pages) => this.handleOpenPreviewPublicationDialog(pubId, pages)}
          fetchAutoSuggestTags={() => this.fetchAutoSuggestTags()}
          showCorrectionCounts={(name, id) => this.showCorrectionCounts(name, id)}
        />
        {this.state.searchResult.totalRows === this.state.searchResult.numberOfRows ? (
          undefined
        ) : (
          <div style={{ margin: 10 }}>
          <Pagination
            limit={this.state.rowsPerPage}
            offset={this.state.searchRowOffset}
            total={Math.min(this.state.searchResult.totalRows, 100 * this.state.rowsPerPage)}
            onClick={(event, offset) => this.setState({searchRowOffset: offset})}
            centerRipple={true}
            style={{ textAlign: "center" }}
          />
          </div>
        )}
      </>
    }


    return (
      <>
        <Helmet>
          <title>{this.props.title}</title>
        </Helmet>
        <Typography variant="h4">{GetResource(Resources.MainMenu_Publications_Publications_Title)}</Typography>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "auto 40%",
            columnGap: 10
          }}
        >
          <SearchBarV2
            onSearch={() => this.handleNewSearch()}
            onSearchTextChanged={text => this.setState({ searchText: text })}
            onSearchFiltersChange={filters => this.setState({ searchFilters: filters })}
            searchFilters={this.state.searchFilters}
            searchText={this.state.searchText}
          />
           <SearchBarWithAutoSuggest
            autoSuggestTags={this.state.autoSuggestTags}
            queryArray={this.state.tagsSearchArray}
            handleChangeTagsSearchArray={(value) => this.handleChangeTagsText(value)}
          />
          {this.state.publicationPreviewDialog ?
            <DialogPreviewPublication
              handleClose={() => this.handleClosePreviewPublicationDialog()}
              publicationId={this.state.publicationPreviewId}
              publicationPages={this.state.publicationPages}
            /> : undefined}
        </div>

        {searchState}
      </>
    );
  }
}

export default connect(state => state)(Publikationstyring);
