import React from "react";
import MUIDataTable from "mui-datatables";
import ActionButtons from "./ActionButtons";
import { getCustomerPhoto } from "../../../helpers/ImageHelper";
import { connect } from "react-redux";
import { fetchCustomers } from "../../../redux/actions/utils/customers.utils.actions";
import {
  loadEntity,
} from "../../../helpers/UtilsReducerHelper";
import {
  getShortDate
} from "../../../helpers/UtilHelper";
import history from "../../../routes/history";
import { Tooltip } from "@material-ui/core";
import { canDeleteApplicationForCustomer, canReadApplicationForCustomer } from "../../../helpers/CredentialsHelper";
import { applications } from "../../../constants/app.constants";

export class DataTable extends React.Component {

  constructor(props) {
    super(props);
    
    this.state = {
      columns: [],
    };

  }

  componentDidMount() {
    this.setState({ columns: this.columns() });

    loadEntity(
      this.props.utilsReducer.customers,
      this.props.dispatch,
      fetchCustomers
    );
  }

  columns() {
    return ["", "Jobnummer", "Kunde", "Publikationsnavn", "Korrektur", "År/Uge", "Sider", "Gyldigt fra", "Gyldigt til", "Format", ""];
  }

  isStatusOpen(status) {
    return status.toLowerCase() === "aktiv";
  }

  async handleView(publication) {
    history.push("/publikation/" + publication.jobNumber);
  }

  getDataTable(props) {
    
    return props.dataRows.map(publication => {
      const publicationCorrections = this.props.corrections.filter(c=>c.publicationId === publication.id);
      let _customer = null;
      let _customerName = "";
      if (this.props.utilsReducer.customers != null) {
        _customer = this.props.utilsReducer.customers.find(
          a => a.id === publication.customerId
        );
        _customerName = _customer == null ? "Ukendt" : _customer.name;
      }

      const isImporting = publication.status === "Importing" ? true : false;

      const getJobNumberColumn = () => {
        
        const canReadFotoHandtering = canReadApplicationForCustomer(applications.FOTOHAANDTERING,publication.customerId);
        const cursorStyle = canReadFotoHandtering && !isImporting ? "pointer" : "text"; 
        const mainDiv = <div
          onClick={() => !isImporting && canReadFotoHandtering ? this.handleView(publication) : undefined}
          style={{ cursor: cursorStyle }}
        >
          {publication.jobNumber}
        </div>;
        return (
          <>
            {!isImporting ? (
              mainDiv
            ) : (
              <Tooltip title="Importerer">
                {mainDiv}
              </Tooltip>
            )
            }
          </>
        );
      }
      return [
        <span style={{ paddingLeft: 20 }} />,
        getJobNumberColumn(),
        <img style={{ width: 50 }} src={getCustomerPhoto(_customerName)} alt={_customerName}></img>,
        publication.name,
        publication.numberOfCorrections > 0 ? publication.numberOfCorrections : "",
        publication.yearAndWeek,
        publication.numberOfPages,
        getShortDate(publication.startDate),
        getShortDate(publication.endDate),
        publication.description,
        <ActionButtons
          publication={publication}
          corrections={publicationCorrections}
          isStatusOpen={publication.status === "Active"}
          handleReload={this.props.onSearch}
          handleView={() => this.handleView(publication)}
          updateDataTable={this.props.updateDataTable}
          handleOpenPreviewPublicationDialog={(pubId, pages) => this.props.handleOpenPreviewPublicationDialog(pubId, pages)}
          fetchAutoSuggestTags={() => this.props.fetchAutoSuggestTags()}
          showCorrectionCounts={(name, id) => this.props.showCorrectionCounts(name, id)}
        />
      ];
    });
  }

  render() {
    const data = this.getDataTable(this.props);
    const options = {
      print: false,
      download: false,
      viewColumns: false,
      selectableRows: false,
      search: false,
      filter: false,
      sort: false,
      pagination: false
    };

    return (
      <MUIDataTable
        data={data}
        columns={this.state.columns}
        options={options}
      />
    );
  }
}

export default connect(state => state)(DataTable);
