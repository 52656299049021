import { Typography } from "@material-ui/core";
import React, { Component } from 'react';
import { connect } from "react-redux";

import { canReadApplication } from "../../../helpers/CredentialsHelper";
import { applications } from "../../../constants/app.constants";
import { LoadingLogo } from "../../shared/Loading";
import DataTable from "./DataTable";
import { Helmet } from "react-helmet";
import { fetchCustomers } from "../../../redux/actions/utils/customers.utils.actions";
import { getPublicationFiltersForRequest, getPublicationFiltersForUi } from "../publikationstyring/PublicationSearchFilters";
import { loadFiltersFromStorage } from "../../../helpers/SearchHelper";
import { searchPublications } from "../publikationstyring/Requests";
import { showErrorNotification } from "../../../helpers/UtilHelper";
import Pagination from "material-ui-flat-pagination";
import SearchBarV2 from "../../shared/components/searchBarV2/SearchBarV2";
import { IsFeatureEnabled, featureNames } from "../../../helpers/FeaturesHelper";


class DigitalMedias extends Component {

  constructor(props) {
    super(props);

    this.state = {
      storageKeyText: "search_text_digital_medias",
      storageKeyFilters: "search_filters_digital_medias",
      searchText: "",
      searchFilters: [], // options to filter on while making search request
      searchResult: null,
      searchRowOffset: 0,
      rowsPerPage: 10,
      loading: true,
    };
  }

  async componentDidMount() {
    if (canReadApplication(applications.DIGITALEMEDIER)) {
      await this.props.dispatch(fetchCustomers());

      let filters = getPublicationFiltersForUi(this.props.utilsReducer.customers);
      let searchText = "";
      // load search request arguments from cache
      try {
        filters = loadFiltersFromStorage(filters, this.state.storageKeyFilters);
        searchText = localStorage.getItem(this.state.storageKeyText);
      } catch { }

      this.setState({
        searchText: searchText,
        searchFilters: filters
      }, () => this.performSearch());
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.state.loading &&
      (prevState.searchRowOffset !== this.state.searchRowOffset || prevState.searchFilters !== this.state.searchFilters)) {
      this.performSearch();
    }
  }

  async performSearch() {
    try {
      this.setState({ loading: true });
      // construct search request
      const searchRequest = {
        searchText: this.state.searchText,
        filters: getPublicationFiltersForRequest(this.state.searchFilters, true, this.props.utilsReducer.customers),
        startRow: this.state.searchRowOffset,
        numberOfRows: this.state.rowsPerPage
      };

      const searchResult = await searchPublications(searchRequest);
      this.setState({ loading: false, searchResult: searchResult });
    } catch (e) {
      showErrorNotification(`Søgning fejlede. ${e.message}`);
      this.setState({ loading: false });
    }

    // cache search request arguments
    localStorage.setItem(this.state.storageKeyText, this.state.searchText);
    localStorage.setItem(this.state.storageKeyFilters, JSON.stringify(this.state.searchFilters));
  };

  handleNewSearch() {
    this.setState({ searchRowOffset: 0 }, () => this.performSearch())
  }

  render() {
    if (!IsFeatureEnabled(featureNames.Publications_Digital_Enabled) || !canReadApplication(applications.DIGITALEMEDIER)) {
      return (
        <Typography variant="subtitle1" align="center">
          Du har ikke adgang til denne side. Kontakt support
          (mbsupport@republica.dk), hvis dette er en fejl.
        </Typography>
      );
    }

    let searchState = null;
    if (this.state.loading) {
      searchState = <LoadingLogo />;
    } else if (!this.state.searchResult || this.state.searchResult.rows.length === 0) {
      searchState = <Typography variant="subtitle1" align="center">Ingen digitale medier fundet...</Typography>;
    } else {
      searchState = <>
        <DataTable
          onSearch={() => this.performSearch()}
          dataRows={this.state.searchResult.rows}
        />
        {this.state.searchResult.totalRows === this.state.searchResult.numberOfRows ? (
          undefined
        ) : (
          <div style={{ margin: 10 }}>
            <Pagination
              limit={this.state.rowsPerPage}
              offset={this.state.searchRowOffset}
              total={Math.min(this.state.searchResult.totalRows, 100 * this.state.rowsPerPage)}
              onClick={(event, offset) => this.setState({ searchRowOffset: offset })}
              centerRipple={true}
              style={{ textAlign: "center" }}
            />
          </div>
        )}
      </>
    }

    return (
      <>
        <Helmet>
          <title>{this.props.title}</title>
        </Helmet>
        <Typography variant="h4">{"Digitale Medier"}</Typography>
        <SearchBarV2
          onSearch={() => this.handleNewSearch()}
          onSearchTextChanged={text => this.setState({ searchText: text })}
          onSearchFiltersChange={filters => this.setState({ searchFilters: filters })}
          searchFilters={this.state.searchFilters}
          searchText={this.state.searchText}
        />
        {searchState}
      </>
    );
  }
}

export default connect(state => state)(DigitalMedias);
