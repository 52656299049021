import React, { useState } from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Button, withStyles } from '@material-ui/core';
import UploadPhotoTab from '../../TabContainers/UploadPhotoTab';
import { endpoints } from '../../../../../constants/services.constants';
import { _request } from '../../../../../helpers/HttpHelper';
import { showErrorNotification, showSuccessNotification } from '../../../../../helpers/UtilHelper';
import { getToken } from '../../../../../helpers/AuthHelper';
import store from '../../../../../redux/store';
import { deletePhotos } from '../../../../../redux/actions/photos/search.photos.actions';
import { patchMaster } from '../../../../../redux/actions/product/edit.products.actions';
import { getProductPhotosForPhoto } from '../../Requests';
import Resources from "../../../../../Resources.json";
const styles = {
    messageTxt: {
        color: "#f44336",
        fontWeight: 500,
        fontSize: "1.6em"
    },
    contentHolder: {
        maxHeight: "760px",
    },
}

function DialogUploadNewProductPhotoBeforeDelete({ classes, productId,
    closeDialogUploadNewPhoto, photoIds, hideUploadButton, performSearch }) {
    const [files, setFiles] = useState([]);
    const [customer, setCustomer] = useState();
    const [photoType, setPhotoType] = useState();
    const [loading, setloading] = useState(false);

    const handleSetfile = (data) => {
        if (data.length !== 0) {
            setFiles(data.map((fileItem) => fileItem.file));
        }
    }

    const addImagesToProduct = async () => {
        if (!customer || !photoType) {
            showErrorNotification("Krævede felter mangler værdier");
            return;
        }

        setloading(true);

        let formData = new FormData();
        formData.append("file", files[0]);
        formData.append("customerId", customer);
        formData.append("Type", photoType);
        formData.append("RelatedProductIds[0]", productId);

        const endpoint = endpoints.PHOTO_UPLOAD;

        const response = await _request({
            method: "POST",
            endpoint: endpoint,
            body: formData,
            skipBodyParsing: false,
            throwError: true,
            skipErrorNotification: false,
            headers: {
                Accept: "application/json, application/xml, text/plain, text/html, *.*",
                Authorization: "Bearer " + getToken(),
                "x-organization-id": Resources.Organization
            }
        });

        // delete old photo if the upload of a new was successful
        if (response) {

            showSuccessNotification("Fotoet er uploaded");
            let deleteDto = {
                photoIds: photoIds.map(x => parseInt(x)),
                reason: 5, // Billedet er udgået, change later to enum value
                details: "",
            };

            store.dispatch(deletePhotos(deleteDto, () => { }));

            // set a new photo as a products default photo
            if (typeof (response) === "number") {
                const productPhoto = await getProductPhotosForPhoto(response);
                if (productPhoto) {
                    store.dispatch(patchMaster(productId, productPhoto[0].id));
                }
            }
        }

        setloading(false);

        closeDialogUploadNewPhoto();
        performSearch();
    }

    return (
        <div>
            <Dialog
                open={true}
                onClose={() => closeDialogUploadNewPhoto()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
            >
                <DialogTitle id="alert-dialog-title">Upload foto</DialogTitle>
                <DialogContent>
                    <div className={classes.contentHolder}>
                        <UploadPhotoTab
                            files={files}
                            setFile={(data) => handleSetfile(data)}
                            handerCustomer={(customerId) => setCustomer(customerId)}
                            handerPhotoType={(photoTypeId) => setPhotoType(photoTypeId)}
                            addImagesToProduct={() => addImagesToProduct()}
                            uploading={loading}
                            hideUploadButton={hideUploadButton}
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => closeDialogUploadNewPhoto()}
                        autoFocus
                    >
                        Annuller
                    </Button>

                    <Button
                        onClick={() => addImagesToProduct()}
                        color="secondary"
                        disabled={loading ? true : false}
                    >
                        {!loading ? "Upload" : "Uploading"}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>

    )
}

export default withStyles(styles)(DialogUploadNewProductPhotoBeforeDelete);