import React, { useEffect, useState } from "react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Image from 'material-ui-image';
import { DialogTitle } from '@material-ui/core';
import { apiGetParamsReplace, endpoints } from '../../../constants/services.constants';
import { getMotivId } from '../../../helpers/UtilHelper';
import createDialog from '../../../DialogRenderer';
import { getPhotoOrderSingleV2, getPhotoOrdersByPhotoIds} from "../../sections/fotohaandtering/Requests";
import { getUser } from "../../../redux/actions/users/users.actions";
import { getPhotoSingle, getUserById } from '../Requests';

import { useSelector } from 'react-redux';

export async function openDialogFullsizePhoto(photoId) {



  return createDialog(({ show, onSubmit, onDismiss }) => (
    <DialogFullsizePhoto photoId={photoId}
      open={show}
      onCancel={onDismiss}
      onConfirm={onSubmit}
    />
  ));
}






function DialogFullsizePhoto({ photoId, open, onCancel }) {

  const [imageLoaded, setImageLoaded] = useState(false); // State to hold the data

  const [orderExecutedByUser, setOrderExecutedByUser] = useState(null); // State to hold the data
  const [showOrderExecutedByUser, setShowOrderExecutedByUser] = useState(false); // State to hold the data

  const [orderCreatedByUser, setOrderCreatedByUser] = useState(null); // State to hold the data
  const [showOrderCreatedByUser, setShowOrderCreatedByUser] = useState(false); // State to hold the data

  const [photoCreatedByUser, setPhotoCreatedByUser] = useState(null); // State to hold the data
  const [showPhotoCreatedByUser, setShowPhotoCreatedByUser] = useState(false); // State to hold the data

  const [userInitialized, setUserInitialized] = useState(false); // State to hold the data

  const photoUrl = apiGetParamsReplace(endpoints.PHOTO_SINGLE_800, [photoId]);
  const motiveId = getMotivId(photoId);

  const reducer = useSelector(state => state.credentialsReducer);

  useEffect(() => {
    const fetchData = async () => {
      
      var userId = undefined;

      //get logged-in user id from first permission available
      var currentUserId = reducer.me[0].multiUserId;
      var currentUser = await getUserById(currentUserId);

      //this is a database defined id - hardcoded for performance reasons to avoid getting it from db
      const republicaDepartmentId = 'b1494ee1-f1d5-4623-ba73-cbf825b3c84b';

      setShowOrderCreatedByUser(false);
      setShowOrderExecutedByUser(false);
      setShowPhotoCreatedByUser(false);

      //only Republica users can see who uploaded the photo
      if(currentUser.aspNetDepartmentId !== republicaDepartmentId){        
        return;
      }

      var orders = await getPhotoOrdersByPhotoIds([photoId]);
  
      //user from order
      if(orders !== undefined && orders.length == 1){

        var order = orders[0];

        setShowOrderCreatedByUser(true);
        setShowOrderExecutedByUser(true);

        setOrderCreatedByUser(null);
        setOrderExecutedByUser(null);

        if(order.createdByUserId != undefined){
          var creatingUser = await getUserById(order.createdByUserId);
          setOrderCreatedByUser(creatingUser.fullname + " ("+creatingUser.email+")")
        }
        if(order.executedByUserId != undefined){
          var executingUser = await getUserById(order.executedByUserId);
          setOrderExecutedByUser(executingUser.fullname + " ("+executingUser.email+")")
        }
      }
      //user from photo
      else{  
        var photo = await getPhotoSingle(photoId);
        if(photo !== undefined && photo.createdByUser != undefined){
          var photoUser = await getUserById(photo.createdByUser);
          setPhotoCreatedByUser(photoUser.fullname + " ("+photoUser.email+")");
        }
        else{
          setPhotoCreatedByUser(null);
        }
        setShowPhotoCreatedByUser(true);
      }
      setUserInitialized(true);
    };  
    fetchData();  
  }, []);
  const handleImageLoad = () => {
    setImageLoaded(true);
  }
  return (
    <Dialog
      open={open}
      onClose={onCancel}
      aria-labelledby="form-dialog-title"
      fullWidth={true}
      maxWidth="sm"
    >
      <DialogTitle>{motiveId}</DialogTitle>
      <DialogContent>
        <Image
           onLoad={handleImageLoad}
           style={{ "position": "unset", "paddingTop": "unset", }}
           imageStyle={{
             position: "unset",
             maxHeight: 800,
             width: "auto",
             height: "auto",
             maxWidth: "100%",
             display:"block",
             marginLeft: "auto",
             marginRight: "auto"   
           }}
           
          disableTransition={true}
          src={photoUrl}
        />
        { (!showPhotoCreatedByUser || !imageLoaded || !userInitialized) ? 
          <></> :
          <div><p/>{"Billedet er tilføjet af: " + (!photoCreatedByUser ? "(ukendt bruger)" : photoCreatedByUser)}</div>
        }
        { (!showOrderCreatedByUser || !imageLoaded || !userInitialized) ? 
          <></> :
          <div><p/>{"Ordren er oprettet af: " + (!orderCreatedByUser ? "(ukendt bruger)" : orderCreatedByUser)}</div>
        }
        { (!showOrderExecutedByUser || !imageLoaded || !userInitialized) ? 
          <></> :
          <div><p/>{"Billedet er tilføjet af: " + (!orderExecutedByUser ? "(ukendt bruger)" : orderExecutedByUser)}</div>
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Luk</Button>
      </DialogActions>
    </Dialog>
  );

}
