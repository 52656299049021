
import Resources from "../Resources.json";

export const services = {
  GATEWAY: process.env.REACT_APP_SERVICE_GATEWAY,
  OFFER: process.env.REACT_APP_SERVICE_OFFER,
  PRODUCT: process.env.REACT_APP_SERVICE_PRODUCT,
  PHOTO: process.env.REACT_APP_SERVICE_PHOTO,
  MEDIA: process.env.REACT_APP_SERVICE_MEDIA,
  MEDIA2: process.env.REACT_APP_SERVICE_MEDIA2,
  NOTIFICATION: process.env.REACT_APP_SERVICE_NOTIFICATION,
  IDENTITY: process.env.REACT_APP_SERVICE_IDENTITY,
  CUSTOMER: process.env.REACT_APP_SERVICE_CUSTOMER,
  USER: process.env.REACT_APP_SERVICE_USER,
  LOGO: process.env.REACT_APP_SERVICE_LOGO,
  WORKFLOW: process.env.REACT_APP_SERVICE_WORKFLOW,
  WORKBOOK: process.env.REACT_APP_SERVICE_WORKBOOK,
  PUBLICATION: process.env.REACT_APP_SERVICE_PUBLICATION,
  FILE: process.env.REACT_APP_SERVICE_FILE,
  WATCHDOG: process.env.REACT_APP_SERVICE_WATCHDOG,
  INTERGRATION: process.env.REACT_APP_SERVICE_INTERGRATION,
  INDESIGN: process.env.REACT_APP_SERVICE_INDESIGN,
  TEMPLATE: process.env.REACT_APP_SERVICE_TEMPLATE,
  TEMPLATE_IMAGE: process.env.REACT_APP_SERVICE_TEMPLATE_IMAGE,
  BRAIN: process.env.REACT_APP_SERVICE_BRAIN,
  DESIGNER_BOTS: process.env.REACT_APP_SERVICE_DESIGNER_BOTS,
  REPORT: process.env.REACT_APP_SERVICE_REPORT_2,
  PHOTOORDER: process.env.REACT_APP_SERVICE_PHOTO_ORDER,
  PHOTOCOMBINATION: process.env.REACT_APP_SERVICE_PHOTOCOMBINATION,
  PHOTOCOMBINATION_PUBLIC: process.env.REACT_APP_SERVICE_PHOTOCOMBINATION_PUBLIC,
  OLDAPI: process.env.REACT_APP_SERVICE_OLDAPI,
  API: process.env.REACT_APP_SERVICE_API,
  APPS_CANVASCORRECTOR: process.env.REACT_APP_SERVICE_APPS_CANVASCORRECTOR,
  GEOMETRIC: process.env.REACT_APP_SERVICE_GEOMETRIC,
  DOCUMENT: process.env.REACT_APP_SERVICE_DOCUMENT,
  COLLECTION: process.env.REACT_APP_SERVICE_COLLECTION,
  FEED: process.env.REACT_APP_SERVICE_FEED,
  FILTER: process.env.REACT_APP_SERVICE_FILTER,
  DCOMEDIA: process.env.REACT_APP_SERVICE_MEDIA,
  DCOTEMPLATE: process.env.REACT_APP_SERVICE_DCOTEMPLATE,
  PHOTOCLIPPING: process.env.REACT_APP_SERVICE_PHOTOCLIPPING,
  RECEIPTGENERATOR: process.env.REACT_APP_SERVICE_RECEIPTGENERATOR,
  TRANSFORMATION: process.env.REACT_APP_SERVICE_TRANSFORMATION,
  TRANSFORMATION_PUBLIC: process.env.REACT_APP_SERVICE_TRANSFORMATION_PUBLIC,
  PUBLICATION_PUBLIC: process.env.REACT_APP_SERVICE_PUBLICATION_PUBLIC,
  HELPPAGE: process.env.REACT_APP_SERVICE_HELPPAGE,

  SEARCH: process.env.REACT_APP_SERVICE_SEARCH,
  AFTEREFFECTSRENDERER: process.env.REACT_APP_SERVICE_AFTEREFFECTSRENDERER,
  FILMMAKER:process.env.REACT_APP_SERVICE_FILMMAKER,
  FRONTENDPOSTER:process.env.REACT_APP_SERVICE_FRONTENDPOSTER,
  STORAGE_PUBLIC:process.env.REACT_APP_SERVICE_STORAGE_PUBLIC,
  IMAGES:process.env.REACT_APP_SERVICE_IMAGES
};
export const endpoints = {
  //#region Offer service
  OFFER_SINGLE: services.OFFER + "/offers/{0}",
  OFFER_SINGLE_V2: services.OFFER + "/v2/offers/{0}",
  OFFER_UPDATE_V2: services.OFFER + "/api/v3/offers/{0}",
  OFFER_EDIT_DTO: services.OFFER + "/offers/dto/{0}",
  OFFER_DELETE_: services.OFFER + "/offers/{0}",
  OFFER_DELETE_PRODUCT_V2: services.OFFER + "/v2/offers/products/{0}",
  OFFER_PRODUCT_SINGLE: services.OFFER + "/offers/products/{0}",
  OFFER_PRODUCT_UPDATE: services.OFFER + "/offers/offerproducts/dto/{0}",
  OFFER_GET_SEGMENTS: services.OFFER + "/offers/{0}/Segments",
  OFFER_GET_OFFERS_WITH_PRODUCTNUMBER: services.OFFER + "/offers/GetAllProductsInOffers/{0}",
  OFFER_RELATED_TO_PHOTO: services.OFFER + "/offers/relatedToPhotoOrVariant/{0}",
  OFFER_PRODUCT_SINGLE_V2: services.OFFER + "/v2/offer-products/get-single/{0}",
  OFFER_MULTIPLE_V2: services.OFFER + "/api/v3/offers/get-multiple-dto?{0}",
  OFFER_PRODUCT_MULTIPLE_V2: services.OFFER + "/v2/offer-products/get-multiple?includeFieldValues={0}&includePhotos={1}",
  OFFER_GET_FOR_PUBLICATION: services.OFFER + "/v2/offers/for-publication/{0}?includeProducts={1}&includeFieldValues={2}&includePhotos={3}",
  OFFER_PRODUCT_PHOTO_ADD_V2: services.OFFER + "/api/v3/offer-product-photos/{0}/add/{1}",
  OFFER_PRODUCT_PHOTO_REMOVE_V2: services.OFFER + "/api/v3/offer-product-photos/{0}/remove/{1}",
  OFFER_PRODUCT_PHOTO_SET_ACTIVE_V2: services.OFFER + "/api/v3/offer-product-photos/{0}/set-active/{1}",
  OFFER_PRODUCT_PHOTO_SET_VARIANT_V2: services.OFFER + "/api/v3/offer-product-photos/{0}/set-variant/{1}/{2}",
  OFFER_PRODUCT_PHOTO_WITH_PHOTO_ID: services.OFFER + "/api/v3/offer-product-photos/with-photo/{0}",
  OFFER_COPY: services.OFFER + "/v2/offers/{0}/copy?{1}",
  OFFER_LATEST_VALID_TO_DATE_PHOTO_USED: services.OFFER + "/offers/getLatestOfferValidToDateWherePhotoIsUsed/{0}", // find all offers where photo is used and return a latest "valid to" date
  OFFER_GET_TAGS: services.OFFER + "/api/v3/offers/tags",
  OFFERS_COPY_MULTIPLE: services.OFFER + "/offers/copy-multiple",
  GET_RANKING_OFFER: services.OFFER + "/rankings/offers/{0}",
  PATCH_OFFER_SCORES: services.OFFER + "/rankings/offers/{0}",
  //#endregion


  //#region Product service
  PRODUCT_SINGLE: services.PRODUCT + "/products/{0}", // update
  PRODUCT_SINGLE_V2: services.PRODUCT + "/v2/product/{0}",
  PRODUCT_DELETE: services.PRODUCT + "/products/{0}",
  PRODUCT_SET_MASTER: services.PRODUCT + "/products/{0}/setmaster/{1}",
  PRODUCT_TOTAL_COUNT: services.PRODUCT + "/products/total/{0}",
  PRODUCT_EDIT: services.PRODUCT + "/api/v3/products/dto/{0}",
  PRODUCT_ADD: services.PRODUCT + "/v2/product/dto",
  PRODUCTS_NEWEST: services.PRODUCT + "/products/newest/{0}",
  PRODUCT_GET_BY_EAN: services.PRODUCT + "/products/getByEANnumber/1/{0}", // 1 - is a structureId which belongs to coop
  PRODUCT_IS_EAN_VALID: services.PRODUCT + "/products/IsValidEanNumber/{0}",
  PRODUCT_ADD_GS1_IMAGES: services.PRODUCT + "/products/{0}/photos/gs1/add",
  PRODUCT_GET_RELATED_TO_OFFER: services.PRODUCT + "/v3/products/images/product-numbers/?customerId={0}",
  PRODUCT_CATEGORIES: services.PRODUCT + "/v2/categories",
  PRODUCT_MAIN_CATEGORIES: services.PRODUCT + "/productMainCategories",
  PRODUCT_PHOTOSBYMOTIVID: services.PRODUCT + "/products/photosByMultiPhotoId/{0}",
  PRODUCT_PHOTOS_FOR_PHOTO_ID: services.PRODUCT + "/product-photos/for-photo/{0}",
  PRODUCT_PHOTOS_ADD: services.PRODUCT + "/product-photos",
  PRODUCT_PHOTOS_DELETE: services.PRODUCT + "/product-photos",
  PRODUCT_ADD_MULTOPHOTOPRODUCT: services.PRODUCT + "/products/{0}/photo",
  PRODUCT_DELETE_MULTOPHOTOPRODUCT: services.PRODUCT + "/products/DeleteProductPhoto/{0}",
  PRODUCT_GETALL_MULTIPHOTO: services.PRODUCT + "/products/getMany",
  PRODUCT_GET_GS1_IMAGES: services.PRODUCT + "/products/{0}/gs1/all",
  PRODUCT_GET_BY_PRODUCTNUMBER_CUSTOMER: services.PRODUCT + "/api/v3/products/productNumber/{0}?customerId={1}",
  PRODUCT_MULTIPLE: services.PRODUCT + "/v2/product/get-multiple?{0}",
  LAST_PRODUCT_PHOTO: services.PRODUCT + "/products/isLastProductPhoto/{0}",
  PRODUCT_TAGS_GET_ALL: services.PRODUCT + "/api/v3/products/tags",
  //#endregion


  //#region Photo service
  PHOTO_SINGLE_200: services.PHOTO + "/api/v3/frontend/motive/{0}/organization/"+Resources.Organization+"/200",
  PHOTO_SINGLE_800: services.PHOTO + "/api/v3/frontend/motive/{0}/organization/"+Resources.Organization+"/800",
  PHOTO_SINGLE_ORG: services.PHOTO + "/api/v3/frontend/motive/{0}/organization/"+Resources.Organization+"/org",
  PHOTO_UPLOAD: services.PHOTO + "/api/v3/photos/create",
  PHOTOS_DELETE: services.PHOTO + "/v2/photos/delete-multiple",
  PHOTO_REPLACE: services.PHOTO + "/photos/{0}/replace",
  PHOTO_INFO_MULTIPLE: services.PHOTO + "/v2/photos/get-multiple",
  PHOTO_INFO_SINGLE: services.PHOTO + "/v2/photos/get-single/{0}",
  PHOTO_UPDATE_V3: services.PHOTO + "/api/v3/photos/{0}/update",
  PHOTO_UPDATE_V2_VALIDDATE: services.PHOTO + "/v2/photos/{0}/updatePhotoExpireDate?date={1}",
  PHOTO_SEND_TO_OCA: services.PHOTO + "/v2/photos/send-to-oca",
  EARLIEST_EXPIRATION_DATE: services.PHOTO + "/v2/photos/{0}/earliestExpirationDate",
  PHOTO_TAGS_GET_ALL: services.PHOTO + "/api/v3/photos/tags",
  //#endregion


  //#region Photo combination service
  PHOTOCOMBINATION_WEEKSEGMENTS_GET: services.PHOTOCOMBINATION + "/api/WeekSegments/{0}/{1}",
  PHOTOCOMBINATION_WEEKSEGMENTS_POST: services.PHOTOCOMBINATION + "/api/WeekSegments",
  PHOTOCOMBINATION_WEEKSEGMENTS_DELETE: services.PHOTOCOMBINATION + "/api/WeekSegments/{0}/{1}/{2}/{3}",
  PHOTOCOMBINATION_WEEKSEGMENTCOMBINATIONS_GET: services.PHOTOCOMBINATION + "/api/WeekSegmentCombinations/{0}/{1}/{2}/{3}",
  PHOTOCOMBINATION_WEEKSEGMENTCOMBINATIONS_POST: services.PHOTOCOMBINATION + "/api/WeekSegmentCombinations",
  PHOTOCOMBINATION_WEEKSEGMENTCOMBINATIONS_DELETE: services.PHOTOCOMBINATION + "/api/WeekSegmentCombinations/{0}",
  PHOTOCOMBINATION_OFFERS_GENERATIONS: services.PHOTOCOMBINATION + "/api/offers/{0}/generations",
  PHOTOCOMBINATION_OFFERS_GENERATIONS_REFRESH: services.PHOTOCOMBINATION + "/api/offers/generations/{0}/refresh",
  PHOTOCOMBINATION_OFFERS_GENERATIONS_REFRESH_POST: services.PHOTOCOMBINATION + "/api/offers/generations/refresh",
  PHOTOCOMBINATION_OFFERS_LUCKY: services.PHOTOCOMBINATION_PUBLIC + "/api/offers/{0}/ifeellucky?width={1}&height={2}&cache={3}",
  PHOTOCOMBINATION_OFFERS_LATEST: services.PHOTOCOMBINATION_PUBLIC + "/api/offers/{0}/latest?width={1}&height={2}&cache={3}",
  PHOTOCOMBINATION_OFFERS_GET_PHOTO_COMBINATION: services.PHOTOCOMBINATION_PUBLIC + "/api/frontend/offers/{0}/latest/organization/"+Resources.Organization+"?width=400&height=400",
  PHOTOCOMBINATION_OFFERS_GET_GENERATION: services.PHOTOCOMBINATION_PUBLIC + "/api/offers/{0}/generations/{1}",
  PHOTOCOMBINATION_OFFERS_GET_GENERATION_INFO: services.PHOTOCOMBINATION_PUBLIC + "/api/offers/{0}/generations/{1}/info",
  PHOTOCOMBINATION_STORE_GETBYID: services.PHOTOCOMBINATION + '/api/store/{0}',
  PHOTOCOMBINATION_GENERATIONS_SENDCALLBACK: services.PHOTOCOMBINATION + '/api/generations/{0}/sendcallback/{1}',
  PHOTOCOMBINATION: services.PHOTOCOMBINATION + '/api?key=D4Jh1JWpvrkxSkD1404FReuHFf0sdCRv',
  PHOTOCOMBINATION_SAVE: services.PHOTOCOMBINATION + '/api/{0}?key=D4Jh1JWpvrkxSkD1404FReuHFf0sdCRv',
  PHOTOCOMBINATION_CORRECTIONS: services.PHOTOCOMBINATION + '/api/corrections?key=D4Jh1JWpvrkxSkD1404FReuHFf0sdCRv&pregenerate=false',
  PHOTOCOMBINATION_ROTATIONRECALCULATION: services.PHOTOCOMBINATION + '/api/rotationrecalculation',
  PHOTOCOMBINATION_GENERATE: services.PHOTOCOMBINATION + '/api/offers',
  PHOTOCOMBINATION_GENERATED: services.PHOTOCOMBINATION + '/api/generated/{0}',
  PHOTOCOMBINATION_ADD_MOTIVES: services.PHOTOCOMBINATION + '/api/offers/generations/addMotives',
    //  PHOTO_TRANSFORM_300: services.PHOTO+'/transform/{0}?format=png&size=300',
    //  PHOTO_TRANSFORM_300_OLDMB: services.IMAGE_OLD+'/motive/{0}?width=300&format=png',
    //  PHOTO_TRANSFORM_1000: services.PHOTO+'/transform/{0}?format=png&size=1000',
    //  PHOTO_TRANSFORM_1000_OLDMB: services.IMAGE_OLD+'/motive/{0}?width=1000&format=png',

  //#endregion


  //#region Photo clipping service
  PHOTOCLIPPING_SEARCH: services.PHOTOCLIPPING + "/requests/search{0}",
  PHOTOCLIPPING_ADD_FILE: services.PHOTOCLIPPING + "/mindworking-asia/file{0}",
  PHOTOCLIPPING_ADD_PHOTO: services.PHOTOCLIPPING + "/mindworking-asia/photo{0}",
  PHOTOCLIPPING_DOWNLOAD: services.PHOTOCLIPPING + "/requests/{0}/download",
  PHOTOCLIPPING_DOWNLOAD_ZIP: services.PHOTOCLIPPING + "/requests/group/{0}/download",
  //#endregion


  //#region Photo order service
  PHOTO_FOTOTRACKER_SET_STATUS_FOR_PHOTO: services.PHOTOORDER + "/v2/orders/status/{0}/photo/{1}/type/{2}/set-status/{3}",
  PHOTO_FOTOTRACKER_RECOVER: services.PHOTOORDER + "/v2/orders/photo/{0}/recover",
  PHOTO_FOTOTRACKER_DELETE_ORDER: services.PHOTOORDER + "/v2/orders/{0}",
  PHOTO_ORDER_ADD_V2: services.PHOTOORDER + "/v2/orders",
  PHOTO_ORDER_SET_STATUS: services.PHOTOORDER + "/v2/orders/{0}/set-status/{1}",
  PHOTOTRACKER_PHOTO_REUSE: services.PHOTOORDER + "/orders/{0}/reusephoto/{1} ",
  PHOTO_FETCH_LISTE: services.PHOTOORDER + "/orders/startreport",
  PHOTO_SET_LISTE: services.PHOTOORDER + "/orders/report",
  REFERENCE_PHOTO_GET_ONE: services.PHOTOORDER + "/files/references/{0}",
  LAYOUT_GET_ONE: services.PHOTOORDER + "/files/layouts/{0}",
  PHOTO_FOTOTRACKER_SINGLE_V2: services.PHOTOORDER + "/v2/orders/get-single/{0}",
  PHOTO_TRACKER_UPLOADPHOTO_V2: services.PHOTOORDER + "/v2/orders/{0}/photo/{1}?overrideResolutionRequirement={2}",
  EDIT_FOTO_ORDER: services.PHOTOORDER + "/v2/orders/{0}",
  PHOTO_ORDERS_GET_BY_PHOTO_IDS: services.PHOTOORDER + "/v2/orders/get-multiple/byPhotoIds",
  PHOTO_ORDER_ADD_BRIEF: services.PHOTOORDER + "/v2/orders/{0}/briefs",
  PHOTO_ORDER_TAGS: services.PHOTOORDER + "/orders/tags",
  PHOTO_ORDER_ADD_GS1: services.PHOTOORDER + "/api/v3/orders/{0}/gs1",
  //#endregion

  //#region Identity service

  FEATURES: services.IDENTITY + "/api/v3/features",

  IDENTTITY_GET_TOKEN: services.IDENTITY + "/connect/token",
  IDENTTITY_REQUEST_RESETCODE: services.IDENTITY + "/sos/resetpasswordgetcode",
  IDENTTITY_RESETPASSWORD_WITHCODE: services.IDENTITY + "/sos/resetpasswordwithcode",
  IDENTTITY_VALIDATE_TOKEN: services.IDENTITY + "/connect/introspect",
  IDENTTITY_CREDENTIALS_ME: services.IDENTITY + "/api/credentials/me",
  DEPARTMENTS_ROOT: services.IDENTITY + "/api/departments",
  USER_ROOT: services.IDENTITY + "/api/users",
  USER_SINGLE: services.IDENTITY + "/api/v3/users/{0}",
  USER_CUSTOMER_ROLE: services.IDENTITY + "/api/v3/users/{0}/customer/{1}/role/{2}",
  USER_ROLES: services.IDENTITY + "/api/v3/users/roles",
  USER_DELETE: services.IDENTITY + "/api/users",
  USER_RESETPASSWORD: services.IDENTITY + "/api/users/resetpassword/{0}",
  USERS_SEARCH: services.IDENTITY + "/api/users/search/{0}",
  USER_ADD_CUSTOMER_PERMITS: services.IDENTITY + "/api/permits/addcustomerpermits",
  USER_ADD_PERMITS: services.IDENTITY + "/api/permits/add",
  USER_REMOVE_PERMITS: services.IDENTITY + "/api/permits/remove",
  USER_REMOVE_CUSTOMER_PERMITS: services.IDENTITY + "/api/permits/removecustomerpermits",
  //#endregion


  //#region Search service
  SEARCH_PUBLICATIONS: services.SEARCH + "/v2/search/publications",
  SEARCH_PRODUCTS: services.SEARCH + "/v3/search/products",
  SEARCH_OFFERS: services.SEARCH + "/v2/search/offers",
  SEARCH_PHOTOS: services.SEARCH + "/v3/search/photos",
  SEARCH_PHOTO_ORDERS: services.SEARCH + "/v2/search/photo-orders",
  SEARCH_PHOTO_ORDER_SINGLE: services.SEARCH + "/v2/search/photo-orders/{0}",
  SEARCH_PUBLICATION_SINGLE: services.SEARCH + "/v2/search/publications/{0}",

  //#endregion


  //#region PUBLICATION service
  PUBLICATION_SINGLE: services.PUBLICATION + "/publications/{0}",
  PUBLICATION_SINGLE_V2: services.PUBLICATION + "/v2/publications/{0}",
  PUBLICATION_ALL: services.PUBLICATION + "/v2/publications",
  PUBLICATION_SIZE: services.PUBLICATION + "/publications/{0}/size",
  PUBLICATION_ADD_OFFER: services.PUBLICATION + "/publications/pages/offers",
  PUBLICATION_SET_OFFER_PAGE: services.PUBLICATION + "/publications/pages/offers/setpage/{0}/{1}",
  PUBLICATION_DELETE_OFFER: services.PUBLICATION + "/publications/pages/offers",
  PUBLICATION_DELETE: services.PUBLICATION + "/publications/{0}",
  PUBLICATION_DELETE_OFFER_ON_PAGE: services.PUBLICATION + "/publications/offer/{0}/{1}",
  PUBLICATION_BACKGROUNDS: services.PUBLICATION + "/publications/backgrounds",
  PUBLICATION_BACKGROUND_PAGES: services.PUBLICATION + "/publications/{0}/backgrounds",
  PUBLICATION_FINALPDF: services.PUBLICATION + "/publications/{0}/pdf",
  PUBLICATION_FRONTPAGE_PERSPECTIVE: services.PUBLICATION + "/publications/{0}/frontpage",
  PUBLICATION_BY_JOBID: services.PUBLICATION + "/publications/ByJobId/{0}",
  PAGES_ALL_PUBLICATION: services.PUBLICATION + "/publications/{0}",
  GET_ALL_ACTIVE_PUBLICATION_BY_OFFERS: services.PUBLICATION + "/publications/offers/multiple?active=true",
  GET_ALL_PUBLICATION_BY_OFFERS: services.PUBLICATION + "/publications/offers/multiple",
  PUBLICATION_INCLUDES_OFFERS: services.PUBLICATION + "/publications/offers/get-multiple",
  PUBLICATION_PATCH: services.PUBLICATION + "/api/v3/publications/{0}",
  PUBLICATION_TAGS: services.PUBLICATION + "/api/v3/publications/tags",
  PUBLICATION_PREVIEW_CATALOG: services.PUBLICATION_PUBLIC + "/publications/{0}/pdfs/{1}.png?key=loxnp2wy32fgreda4gxehhchzdjbcdrw6nb",
  PUBLICATION_BY_JOBID_V2: services.PUBLICATION + "/v2/publications/by-job-id/{0}?{1}",
  GET_EXISTING_PDFS_FOR_PAGES: services.PUBLICATION + "/publications/{0}/pdfs/existingPreviews",

  PUBLICATION_CONTENT_DESCRIPTION: services.PUBLICATION + "/v2/publications/pages/{0}/content-description",

  PUBLICATION_SET_PHOTO_IS_DEACTIVATED: services.PUBLICATION + "/api/v3/publications/{0}/photos/set-is-deactivated",
  PUBLICATION_OFFER_GET_DEACTIVATED_PHOTOS: services.PUBLICATION + "/api/v3/publications/{0}/photos/deactivated?offerId={1}",
  PUBLICATION_GET_DEACTIVATED_PHOTOS : services.PUBLICATION + "/api/v3/publications/{0}/photos/deactivated",

  //#region Report service
  PUBLICATION_CORRECTION_BY_ID: services.REPORT + "/Corrections/byId/{0}/publications/{1}",
  PUBLICATION_CORRECTION_ADD: services.REPORT + "/Corrections/publications/{0}/create",
  PUBLICATION_CORRECTIONS_GET: services.REPORT + "/Corrections/publications/many",
  MONSTRING_ARK: services.REPORT + "/Reports/monstringsark/{0}",
  TBA_REPORT: services.REPORT + "/Reports/publicationElektronisk/{0}",
  PUBLICATION_CORRECTION_CHANGES_BY_ID: services.REPORT+"/corrections/publications/{0}/recent-corrections",
  //#endregion

  //#region Activity service
  GET_JOBID_AUTOCOMPLETE: services.WORKBOOK + "/workbooks/autoComplete/{0}",
  IS_VALID_JOB: services.WORKBOOK + "/workbooks/validJob/{0}",
  //#endregion


  //#region Media service
  MEDIA_SINGLE: services.MEDIA + "/medias/{0}",
  MEDIA_MOTIVE_SINGLE: services.MEDIA + "/motive/{0}",
  MEDIA_MOTIVE_SINGLE_200: services.MEDIA + "/motive/{0}/200",
  MEDIA_MOTIVE_SINGLE_800: services.MEDIA + "/motive/{0}/800",
  MEDIA_MOTIVE_SINGLE_1000: services.MEDIA + "/motive/{0}/1000",
  MEDIA_MOTIVE_SINGLE_ORG: services.MEDIA + "/motive/{0}/org",
  MEDIA_GTIN: services.MEDIA + "/medias/gtin/{0}",
  //#endregion


  //#region Log service
  LOG_ADD: services.LOG + "/logs",
  //#endregion


  //#region Customer service
  CUSTOMER_ALL: services.CUSTOMER + "/v3/customers",
  CUSTOMER_SINGLE: services.CUSTOMER + "/customers/{0}",
  CUSTOMER_SINGLE_LOGO: services.CUSTOMER + "/customers/{0}/logo",
  CUSTOMER_ALL_ALLOWED: services.CUSTOMER + "/customers/me", // get all the customers that current user has access to
  //#endregion

  //#region Dco media service
  MEDIA_TRANSPORTS_GET_ALL: services.DCOMEDIA + "/MediaDefinitions/Transports",
  MEDIATYPES_GET_ALL: services.DCOMEDIA + "/MediaDefinitions/MediaTypes",
  CREATE_MEDIADEFINITION: services.DCOMEDIA + "/MediaDefinitions",
  MEDIADEFINITIONS_GET_ALL: services.DCOMEDIA + "/MediaDefinitions",
  MEDIADEFINITIONS_DELETE_SINGLE: services.DCOMEDIA + "/MediaDefinitions?{0}",
  MEDIADEFINITIONS_GET_LATEST_MEDIA: services.DCOMEDIA + "/MediaDefinitions/{0}/Media/Latest",
  PRODUCE_MEDIA: services.DCOMEDIA + "/Medias",
  MEDIA_GET_ALL: services.DCOMEDIA + "/MediaDefinitions",
  //#endregion



  //#region Old API service
  OLDAPI_OFFERS_BY_CUSTOMER_SEGMENT_WEEK: services.API + "/v2/offers/ranked?organization={0}&segment={1}&yearweek={2}",
  OLDAPI_OFFERS_BY_CUSTOMER_CUPOFTHEMONTH: services.API + "/v2/offers/types/coupofthemonth/{0}?organization={1}",
  //#endregion


  //#region Template service
  TEMPLATES_ALL: services.TEMPLATE + "/templates/",
  TEMPLATES_SINGLE: services.TEMPLATE + "/templates/{0}",
  TEMPLATE_UPLOAD: services.TEMPLATE + "/templates/import/{0}",
  TEMPLATES_CUSTOM_STARTING_GRID: services.TEMPLATE + "/templates/startinggriddocument/templates/{0}/numOffers/{1}",
  TEMPLATE_IMAGE: services.TEMPLATE_IMAGE + "/{0}/_org",
  TEMPLATE_IMAGE_PREVIEW: services.TEMPLATE_IMAGE + "/{0}/200",
  //#endregion


  //#region Logo service
  LOGO_SINGLE: services.LOGO + "/logos/get-single/{0}",
  LOGO_ADD: services.LOGO + "/logos/create",
  LOGO_UPDATE: services.LOGO + "/logos/update/{0}",
  LOGO_DELETE: services.LOGO + "/logos/delete/{0}",
  LOGO_REFRESH_PREVIEW: services.LOGO + "/logos/{0}/refresh-preview",
  LOGO_SEARCH: services.LOGO + "/search?{0}",
  LOGO_FILE_ORIGINAL: services.LOGO + "/public/logos/{0}/original",
  LOGO_FILE_PREVIEW: services.LOGO + "/public/logos/{0}/preview",

  //#endregion


  //#region Filter service
  FILTER_GET_PROPERTIES: services.FILTER + "/Filters/properties/type/{0}",
  FILTER_POST: services.FILTER + "/Filters",
  FILTER_PATCH: services.FILTER + "/Filters/{0}",
  FILTER_GET_SINGLE: services.FILTER + "/Filters/{0}",
  FILTER_EXISTS: services.FILTER + "/Filters/{0}/exists",
  //#endregion


  //#region Feed service
  FEEDS_ADD_FEED: services.FEED + "/Feed",
  FEEDS_GET_ALL: services.FEED + "/Feed",
  COLLECTION_COUNT_FEEDS: services.FEED + "/Feed/Count/Collections/{0}",
  FEED_APPLY_FILTER: services.FEED + "/Feed/{0}/filter/apply",
  //#endregion


  //#region Collection service
  COLLECTIONS_GET_ALL: services.COLLECTION + "/collection",
  COLLECTIONS_ADD_COLLECTION: services.COLLECTION + "/collection",
  COLLECTIONS_DELETE_COLLECTIONS: services.COLLECTION + "/collection?{0}",
  COLLECTIONS_GET_STATUS: services.COLLECTION + "/collection/{0}/status", // get status of the request create new collection
  //#endregion


  //#region Receipt generator service
  RECEIPTGENERATOR_DOWNLOAD_RECEIPTS: services.RECEIPTGENERATOR + "/generate",
  RECEIPTGENERATOR_GET_TEMPLATE: services.RECEIPTGENERATOR + "/template",
  //#endregion


  //#region Geometric service
  GEOMETRIC_IDML: services.GEOMETRIC + "/geometric/spread", //TODO use gateway.
  GEOMETRIC_IDML_SINGLE: services.GEOMETRIC + "/geometric/spread/{0}",
  //#endregion



  //#region Misc
  PHOTO_GS1_IMAGES:services.IMAGES + "/gs1/images/{0}?key=yznp2wy32reda4gxehcchzdjbcdrw6b5&filter=false",
  INDESIGN_GENERATE_NEW: services.INDESIGN + "/api/v3/indesign/generate/publications/pages",
  INDESIGN_NONFOOD_POSTER: services.INDESIGN + "/api/indesign/generate/posters/nonfood",
  INDESIGN_GREENLAND_POSTER: services.INDESIGN + "/api/indesign/generate/posters/brugseni",
  INDESIGN_STAERK_PRIS_POSTER: services.INDESIGN + "/api/indesign/generate/posters/staerkpris",
  INDESIGN_GET_TEMPLATES: services.INDESIGN + "/api/indesign/customers/{0}/templates/type/{1}",
  APPS_CANVASCORRECTOR_GET: services.APPS_CANVASCORRECTOR + "/{0}",
  TEMPLATES_GET_ALL: services.DCOTEMPLATE + "/Templates",
  PHOTO_TRANSFORM: services.TRANSFORMATION + "/transformimage/fromstorage/bymotiv/{0}/transformstandard/ondemand",
  GET_THUMBNAIL_FROM_STORAGE: services.TRANSFORMATION + "/transformImage/fromStorage/byMotiv/{0}?size={1}&backgroundColor=white&sendToStorage=true",
  INDESIGN_GENERATE_POSTER: services.INDESIGN + "/api/indesign/generate/offers/{0}/poster",
  INDESIGN_GENERATE_POSTERS_FOR_PAGES: services.INDESIGN + "/api/indesign/generate/posters",

  //#region NOTIFICATION service
  NOTIFICATION_ALL: services.NOTIFICATION + "/notifications/type/1",
  NOTIFICATIONS_SIGNALR: services.NOTIFICATION + "/notificationshub",
  NOTIFICATION_SET_AS_READ: services.NOTIFICATION + "/notifications/setIsRead",
  SUBSCRIBE_USER_TO_CHANNEL_RECORD: services.NOTIFICATION + "/subscribe/user/tochannel/record", // fx channel PhotoOrderUpdated orderId=53677, singelton
  SUBSCRIBE_USER_TO_CHANNEL_RECORDS: services.NOTIFICATION + "/subscribe/user/tochannel/records",
  USUBSCRIBE_USER_FROM_CHANNEL_RECORD: services.NOTIFICATION + "/unsubscribe/user/fromchannel/record",
  USUBSCRIBE_USERS_FROM_CHANNEL_RECORD: services.NOTIFICATION + "/usubscribe/user/fromchannel/records",
  USUBSCRIBE_USERS_FROM_CHANNEL_RECORDs: services.NOTIFICATION + "/usubscribe/users/fromchannel/records",
  IS_USER_SUBSCRIBED_TO_CAHNNEL_RECORD: services.NOTIFICATION + "/user/isSubscribed/toChannel/{0}/record/{1}",
   //#endregion

  //#endregion

  //#region AE RENDERER service
  AER_OFFERSCOLLECTION: services.AFTEREFFECTSRENDERER + "/offerscollection",
  AER_OFFERSCOLLECTION_STATE: services.AFTEREFFECTSRENDERER + "/offerscollection/{id}",
  AER_OFFERSCOLLECTION_RESULT: services.AFTEREFFECTSRENDERER + "/offerscollection/{id}/results/{name}",
  AER_TEMPLATES: services.AFTEREFFECTSRENDERER + "/api/filmmaker/templates",
  AER_TEMPLATES_LATESTS: services.AFTEREFFECTSRENDERER + "/api/filmmaker/templates/latests",
  AER_TEMPLATES_OFFERS: services.AFTEREFFECTSRENDERER + "/api/filmmaker/templates/offers",
  AER_TEMPLATE_PREVIEW_MP4: services.AFTEREFFECTSRENDERER + "/api/filmmaker/templates/{id}/results/mp4",
  AER_TEMPLATE_PREVIEW_GIF: services.AFTEREFFECTSRENDERER + "/api/filmmaker/templates/{id}/results/gif",
  AER_RENDER_RESULT_MP4: services.AFTEREFFECTSRENDERER + "/api/filmmaker/renders/{id}/results/mp4",
  AER_RENDER_RESULT_GIF: services.AFTEREFFECTSRENDERER + "/api/filmmaker/renders/{id}/results/gif",
  AER_TEMPLATE_DATA: services.AFTEREFFECTSRENDERER + "/api/filmmaker/templates/{id}",
  AER_TEMPLATE_STEP1: services.AFTEREFFECTSRENDERER + "/api/filmmaker/templates/step1",
  AER_TEMPLATE_STEP2: services.AFTEREFFECTSRENDERER + "/api/filmmaker/templates/step2/{id}",
  AER_TEMPLATE_STEP3: services.AFTEREFFECTSRENDERER + "/api/filmmaker/templates/step3/{id}",
  AER_TEMPLATE_DELETE: services.AFTEREFFECTSRENDERER + "/api/filmmaker/templates/{id}/delete",
  AER_RENDER_DELETE: services.AFTEREFFECTSRENDERER + "/api/filmmaker/renders/{id}/delete",
  AER_RENDER_CREATE: services.AFTEREFFECTSRENDERER + "/api/filmmaker/renders",
  AER_RENDERS_QUEUE: services.AFTEREFFECTSRENDERER + "/api/filmmaker/renders/queue",
  //#endregion

  //#region Transformation service
  TRANSFORMATION_GET_MOTIV_BASELINE:services.TRANSFORMATION_PUBLIC + "/transformImage/fromStorage/byMotiv/{id}/png-baseline",
  TRANSFORMATION_V2_GET_MOTIV_SIZE:services.TRANSFORMATION_PUBLIC + "/frontend/org/"+Resources.Organization+"/fromStorage/byMotiv/{id}?w={size}&o=png",
  //#endregion 

  //#region filmmaker service
  FILMMAKER_ASSETS: services.FILMMAKER + "/api/assets",
  FILMMAKER_ASSETS_ID: services.FILMMAKER + "/api/assets/{id}",
  FILMMAKER_ASSETS_BYSTATES: services.FILMMAKER + "/api/assets/customer/{0}/byStates",
  FILMMAKER_SCREENS_GET_FOR_CUSTOMER: services.FILMMAKER + "/api/screens/customer/{0}",
  FILMMAKER_SCREENS: services.FILMMAKER + "/api/screens",
  FILMMAKER_SCREEN: services.FILMMAKER + "/api/screens/{id}",
  FILMMAKER_SCREEN_RENDER: services.FILMMAKER + "/api/screens/{id}/render",
  FILMMAKER_POSTER_TO_PNG: services.FILMMAKER + "/api/posters",
  FILMMAKER_TEMPLATES: services.FILMMAKER + "/api/templates",
  FILMMAKER_TEMPLATES_GET_FOR_CUSTOMER: services.FILMMAKER + "/api/templates/customer/{0}",
  FILMMAKER_TEMPLATES_CHECKNAME: services.FILMMAKER + "/api/templates/checkName/{name}",
  FILMMAKER_BACKGROUND_POST: services.FILMMAKER + "/api/posters/background",
  FILMMAKER_BACKGROUND_GET_ALL: services.FILMMAKER + "/api/posters/background/customer/{0}",
  FILMMAKER_BACKGROUND_DELETE: services.FILMMAKER + "/api/posters/background/{0}",

  //#endregion 

  //#Help page Service
  HELPPAGE_Assets_Pages: services.HELPPAGE + "/HelpPages",
  HELPPAGE_Get_Single_Page: services.HELPPAGE + "/HelpPages/{0}",
  HELPPAGE_Get_Single_PictureFile : services.HELPPAGE + "/HelpPageFiles/paths/{0}",
  HELPPAGE_Post_Single_PictureFile : services.HELPPAGE + "/HelpPageFiles",
  //#endregion


  //#region POSTER
  FRONTENDPOSTER_TEMPLATE1:services.FRONTENDPOSTER+"/template1",

  //#endregion 

  //#region POSTER
  //TODO: standarize '/', this json response includes it
  STORAGE_PUBLIC_FILMMAKER:services.STORAGE_PUBLIC+"{path}?key=mb2022"

  //#endregion 


};

export const features = {
  GENERATE_INDESIGN_ALL_PAGES: process.env.REACT_APP_FEATURE_GENERATE_NEW_INDESIGN_ALL_PAGES

}


export const configurations = {
  IDENTITY_GRANT_TYPE: "password",
  IDENTITY_CLIENT_ID: "client",
  IDENTITY_CLIENT_SECRET: "511536EF-F270-4058-80CA-1C89C192F69A",
  IDENTITY_SCOPE: "REPUBLICA_SERVICES",
  IDENTITY_RESOURCE: "RepublicaResource",
};

export function apiGetParamsReplace(endpoint, ...keys) {
  var result = endpoint;
  keys.forEach(function (key, i) {
    result = result.replace("{" + i + "}", key);
  });
  return result;
}

