import React from 'react';
import { Dialog, DialogContent, DialogActions, Button, DialogTitle, DialogContentText, MuiThemeProvider } from '@material-ui/core';
import { connect } from 'react-redux';
import createDialog from '../../../DialogRenderer';
import message from '../../../redux/reducers/message';

export async function openDialogConfirm(title, message, confirmButtonText = "Bekræft", confirmButtonColor = "primary") {
    var result = await createDialog(({ show, onSubmit, onDismiss }) => (
        <DialogConfirm title={title}
            confirmButtonText={confirmButtonText}
            open={show}
            onCancel={onDismiss}
            confirmButtonColor={confirmButtonColor}
            onConfirm={onSubmit}>
            <DialogContentText>
                {message}
            </DialogContentText>
        </DialogConfirm>
    ));
    return result;
}

export async function openDialogConfirmDelete(title, message) {
    return openDialogConfirm(title, message, "Slet", "secondary");
}

// Dialog with a header, an array of messages and a single accept button (Ok)
export async function openDialogAccept(title, messages, buttonText = "Ok", buttonResult = true)
{
    var result = await createDialog(({show, onSubmit}) =>(
        <DialogAccept title={title}
            buttonText={buttonText}
            buttonResult={buttonResult}
            open={show}
            onConfirm={onSubmit}>      

            {messages.map((text, index) => (
                <DialogContentText key={index}>
                    {text}
                </DialogContentText>
            ))}
            
        </DialogAccept>
    ));
    return result;
}

function DialogAccept({ open, title, buttonText = "Ok", buttonResult = true, optionButtonColor = "primary", onConfirm, children }) {
    return (
        <Dialog open={open}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                {children}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onConfirm(buttonResult)} color={optionButtonColor} name="buttonGenericLeft">
                    {buttonText}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export async function openDialogYesNoCancel(title, message, leftButtonText = "Nej", leftButtonResult = false, rightButtonText = "Ja", rightButtonResult = true, optionButtonColor = "primary")
{
    var result = await createDialog(({show, onSubmit, onDismiss}) =>(
        <DialogYesNoCancel title={title}
            leftButtonText={leftButtonText}
            rightButtonText={rightButtonText}
            leftButtonResult={leftButtonResult}
            rightButtonResult={rightButtonResult}            
            optionButtonColor={optionButtonColor}
            open={show}
            onCancel={onDismiss}
            onConfirm={onSubmit}>                
            <DialogContentText>
                {message}
            </DialogContentText>
        </DialogYesNoCancel>
    ));
    return result;
}

export async function openConfirmDialogAdvanced(title, dialogContent, confirmButtonText = "Bekræft", confirmButtonColor = "primary") {
    var result = createDialog(({ show, onSubmit, onDismiss }) => (
        <DialogConfirm title={title}
            confirmButtonText={confirmButtonText}
            confirmButtonColor={confirmButtonColor}
            open={show}
            onCancel={onDismiss}
            onConfirm={onSubmit}>
            {dialogContent}
        </DialogConfirm>
    ));
    return result;
}



function DialogConfirm({ open, title, confirmButtonText = "Bekræft", confirmButtonColor = "primary", cancelButtonText = "Annuller", onCancel, onConfirm, children }) {
    return (
        <Dialog open={open}
            onClose={onCancel}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                {children}
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel}>
                    {cancelButtonText}
                </Button>
                <Button onClick={onConfirm} color={confirmButtonColor} name="buttonGenericConfirm">
                    {confirmButtonText}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

function DialogConfirmShadow({ open, title, confirmButtonText = "Bekræft", confirmButtonColor = "primary", cancelButtonText = "Annuller", onCancel, onConfirm, children }) {
    return (
        <Dialog open={open}
            onClose={onCancel}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                {children}
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel}>
                    {cancelButtonText}
                </Button>
                <Button onClick={() => onConfirm()} color={confirmButtonColor} name="buttonGenericConfirm">
                    {confirmButtonText}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default connect(state => state)(DialogConfirm)


function DialogYesNoCancel({ open, title, rightButtonText = "Ja", leftButtonText = "Nej", rightButtonResult = true, leftButtonResult = false, optionButtonColor = "primary", cancelButtonText = "Annuller", onCancel, onConfirm, children }) {
    return (
        <Dialog open={open}
            onClose={onCancel}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                {children}
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel}>
                    {cancelButtonText}
                </Button>
                <Button onClick={() => onConfirm(leftButtonResult)} color={optionButtonColor} name="buttonGenericLeft">
                    {leftButtonText}
                </Button>
                <Button onClick={() => onConfirm(rightButtonResult)} color={optionButtonColor} name="buttonGenericRight">
                    {rightButtonText}
                </Button>
            </DialogActions>
        </Dialog>
    )
}