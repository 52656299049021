import { green, grey, orange, red, yellow } from "@material-ui/core/colors"

export const collectionTypes = [
    { id: 1, name: "MultiBasen" }
]
export const exclusiveCustomersCoop = [
    { id: 24, name: "SuperBrugsen" },
    { id: 25, name: "Kvickly" },
    { id: 29, name: "Irma" },
    { id: 100, name: "Coop 365" }
]

export const exclusiveCustomersJemFix = [
    { id: 51, name: "Jem&Fix Danmark" },
    { id: 52, name: "Jem&Fix Sverige" },
    { id: 56, name: "Jem&Fix Norge" },
]

export const exclusiveCustomersMatas = [
    { id: 103, name: "Matas" },
]

export const exclusiveCustomersOrganization = [

    {organization: "1", customers: exclusiveCustomersCoop },
    {organization: "2", customers: exclusiveCustomersJemFix },
    {organization: "6", customers: exclusiveCustomersMatas },
]




export const photoClippingStatus = {
    "New": "Ny",
    "InProgress": "Sendt til fritlægning",
    "Complete": "Fritlagt",
    "Cancelled": "Cancelled",
    "Failed": "Fejlet",
}

export const photoDeleteReason = {
    "NewPackaging": "Ny emballage",
    "ProductExpired": "Produkt udgået",
    "Copyrights": "Copyright-årsager",
    "InsufficientQuality": "For ringe billedkvalitet",
    "PhotoExpired": "Billedet er udgået",
    "PhotoOrderDeleted": "Fotobestillingen er blevet slettet"
}

export const photoTypeEnum = {
    "Pack": { id: 3, key: "Pack", name: "Packshot" },
    "Environment": { id: 6, key: "Environment", name: "Miljø" },
}

export const photoOrderTypeEnum = {
    "Pack": { id: 3, key: "Pack", name: "Pack", photoType: photoTypeEnum.Pack },
    "PackShadow": { id: 5, key: "PackShadow", name: "Pack - skygge", photoType: photoTypeEnum.Pack },
    "PackGine": { id: 4, key: "PackGine", name: "Gine", photoType: photoTypeEnum.Pack },
    "PackHanger": { id: 13, key: "PackHanger", name: "Bøjle", photoType: photoTypeEnum.Pack },
    "PackDigital": { id: 12, key: "PackDigital", name: "Digital pack", photoType: photoTypeEnum.Pack },
    "Environment": { id: 6, key: "Environment", name: "Miljø - miljø", photoType: photoTypeEnum.Environment },
    "EnvironmentShadowMultiple": { id: 7, key: "EnvironmentShadowMultiple", name: "Miljø - skygge flere varer", photoType: photoTypeEnum.Environment },
    "EnvironmentShadowCooking": { id: 8, key: "EnvironmentShadowCooking", name: "Miljø - skygge tilberedning", photoType: photoTypeEnum.Environment },
    "EnvironmentShadowRegi": { id: 9, key: "EnvironmentShadowRegi", name: "Miljø - skygge regi", photoType: photoTypeEnum.Environment },
    "EnvironmentModel": { id: 10, key: "EnvironmentModel", name: "Miljø - model miljø", photoType: photoTypeEnum.Environment },
    "EnvironmentModelClipped": { id: 11, key: "EnvironmentModelClipped", name: "Miljø - model til frit", photoType: photoTypeEnum.Environment },
}

export const mainPhotoTypes = {
    "Packshot": 1,
    "Miljø": 2
}

export const photoAngleEnum = {
    "Front": { id: 1, name: "Forfra" },
    "Left": { id: 2, name: "Venstre" },
    "Top": { id: 3, name: "Top" },
    "Back": { id: 4, name: "Bagfra" },
    "Right": { id: 5, name: "Højre" },
    "Bottom": { id: 6, name: "Bund" },
}

export const photoStatusEnum = {
    "Active": { id: 1, key: "Active", name: "Aktivt", color: grey[900] },
    "PhotoBeingTaken": { id: 2, key: "PhotoBeingTaken", name: "Foto på vej", color: grey[700] },
    "PhotoBeingClipped": { id: 3, key: "PhotoBeingClipped", name: "Afventer fritlægning", color: yellow[700] },
    "PhotoClipped": { id: 4, key: "PhotoClipped", name: "Fritlagt", color: green[500] },
    "DoesNotExist": { id: 8, key: "DoesNotExist", name: "Findes ikke", color: grey[900] },
    "Deleted": { id: 9, key: "Deleted", name: "Slettet", color: red[500] },
}

export const offerDefinitionsEnum = {
    "EndeligFredag": {id: 1, name: "Endelig Fredag" },
    "WowDage": {id: 3, name: "Wow dage" },
    "Apppris": {id: 4, name: "Apppris" },
    "MånedensKøb": {id: 5, name: "Månedens køb" },
    "StærkPris": {id: 6, name: "Stærk pris" },
    "FastLavPris": {id: 7, name: "Fast lav pris" },
    "Kronemarked": {id: 9, name: "Kronemarked" },
    "UgensUdvalgte": {id: 11, name: "Ugens Udvalgte" },
    "Vinfestival": {id: 14, name: "Vinfestival" },
    "HejHverdag": {id: 18, name: "Hej Hverdag" },
    "Fødselsdag": {id: 19, name: "Fødselsdag" },
    "Ugens365": {id: 20, name: "Ugens 365" },
    "AvisStart365": {id: 21, name: "Avisstart 365" },
    "AlwaysOnCoop": {id: 22, name: "Always on Coop" },
    "AlwaysOnDagliBrugsenDisplay": {id: 23, name: "Always on Dagli Brugsen Display"},
    "AlwaysOnDagliBrugsenSocial" : {id: 26, name: "Always on Dagli Brugsen Social"},
    "ÄnglamarkNonfood": {id: 24, name: "Änglamark Nonfood"},
    "Änglamarkfood": {id: 25, name: "Änglamark food"},
    "HverdagCoop": {id: 27, name: "Hverdag Coop"},
    "WeekendCoop": {id: 28, name: "Weekend Coop"}



}

// Additional properties on offer that are stored in offerFieldValueItems
export const customers = {
    "24": "SuperBrugsen",
    "25": "Kvickly",
    "27": "LokalBrugsen",
    "28": "Dagli'Brugsen",
    "29": "Irma",
    "31": "Metro",
    "32": "Fakta",
    "33": "DLG",
    "36": "DRRB",
    "37": "Optimera",
    "39": "Republica Test",
    "40": "Danske Medlemskæder",
    "41": "FDB",
    "42": "Fleur",
    "45": "L'EASY",
    "46": "Irma Emballage",
    "48": "Grønland",
    "49": "Fakta Q",
    "50": "Fakta Tyskland",
    "51": "Jem&Fix Danmark",
    "52": "Jem&Fix Sverige",
    "53": "Coop.dk Shopping",
    "56": "Jem&Fix Norge",
    "57": "Republica IT",
    "99": "Global Customer"
}

export const customersEnum = Object.fromEntries(
    Object.entries(customers).map(([key, value]) => [value, Number(key)])
);

export const customersDictionary = {
    "Brugseni" : {id: 48, name: "Brugseni"},
    "Kvickly" : {id: 25, name: "Kvickly"}
}

export const photoOrderOrigin = {
    "Bgc": 1,
    "PhotoTracker": 2,

    "Publication": 3
}

export const offerType = {
    "Mix": { id: 1, name: "Mix" },
    "Match": { id: 2, name: "Match" },
    "MixMatch": { id: 3, name: "MixMatch" },
    "StigeMix": { id: 4, name: "Stigemix" },
    "FlatMix": { id: 5, name: "Fladt mix" },
    "ThreeForTwo": { id: 6, name: "3 for 2" },
    "CombinedMix": { id: 7, name: "Sammensat mix" },
    "MixPercent": { id: 8, name: "Mix Procent" },
    "MixThreeForTwo": { id: 9, name: "Mix 3 for 2" },
    "Digital": { id: 10, name: "Digital" },
    "MixMember": { id: 11, name: "Mix Medlem" },
    "MixApp": { id: 12, name: "Mix App" },
    "PageElement": { id: 13, name: "Page Element" }
}

export const photoOrderPriorityEnum = {
    "Normal": 0,
    "Urgent": 1,
    "SuperUrgent": 2
}

export const photoOrderStatus = {
    "ProductNotReceived": { key: "ProductNotReceived", id: 10, name: "Vare ikke modtaget" },
    "ReadyForPhotography": { key: "ReadyForPhotography", id: 20, name: "Klar til foto" },
    "ProductPhotographed": { key: "ProductPhotographed", id: 30, name: "Vare fotograferet" },
    "ReUse": { key: "ReUse", id: 70, name: "Genbrug" },
    "ProductReceived": { key: "ProductReceived", id: 80, name: "Vare i hus" },
    "DeleteOrder": { key: "DeleteOrder", id: 99, name: "Slet bestilling" },
    "PhotoTrackerUpload": { key: "PhotoTrackerUpload", id: 100, name: "Fototracker upload" },
    "ReShoot": { key: "ReShoot", id: 110, name: "Omskud" },
    "Inactive": { key: "Inactive", id: 120, name: "Ikke aktiv" },
    "Deleted": { key: "Deleted", id: 999, name: "Slettet" }
}

export const filmMakerTemplateState = {
    "PENDING": "AFVENTER",
    "PENDING_DATA": "AFVENTER",
    "CREATED": "FÆRDIG",
    "BAD_REQUEST": "FEJL I FIL",
    "ERROR": "FEJL",
    "LOST_RENDER": "TABT FIL",
    "CANCELED": "SLETTET",
    "TESTED_FAILURE": "FEJL I TEST"
}

export const jobNumberCustomerRelations = {
    "10": { id: 32, name: "Fakta" },
    "11": { id: 24, name: "SuperBrugsen" },
    "17": { id: 28, name: "Dagli'Brugsen" },
    "20": { id: 25, name: "Kvickly" },
    "30": { id: 29, name: "Irma" },
    "51": { id: 100, name: "Coop 365" },
    "66": { id: 48, name: "Grønland" }
}

export const allowedInfoTextOfferFilmmaker = new Set(["varebeskrivelse", "flerevarianter", "varebeskrivendemaengde", "prissammenligning", "maengdebegraensning", "fritvalg"]);

export const notificationChannelsEnum = {
    "PublicationUpdated": 1
}

export const labelTexts = {
    PhotoType: "Fototype",
    Name: "Navn",
    Customer: "Kunde",
    Descriptioin: "Beskrivelse",
    Tags: "Tags"
}

export const TooltipTexts = {
    Edit: "Rediger",
    Convert: "Konvertér",
    Delete: "Slet",
    Download: "Hent",
    ShowPhoto: "Vis foto",
    UploadPhoto: "Upload foto",
    Chat: "Samtale center",
    MoreOptions: "Flere muligheder"
}


export const TemplateTypes = {
    Publication : 1,
    Poster : 2
}

export const helpPageUsersAddEditDeleteRights = new Set([
    "jakobg@republica.dk",
    "jg@republica.dk",
    "dma",
    "DanGmail",
    "nfh"
])

export const allowedPhotoFormatArray = ["image/jpeg", "image/jpg", "image/png"];

