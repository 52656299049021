import React, { Component } from "react";
import { connect } from "react-redux";
import MUIDataTable from "mui-datatables";
import ActionButtons from "./ActionButton";
import { Typography, LinearProgress } from "@material-ui/core";
import { userDepartments } from "../../../constants/app.constants";

export class SortingSelecting extends Component {
  constructor(props) {
    super(props);

    this.setDataTable = this.setDataTable.bind(this);
  }

  handleCreatedDate = s => {
    const d = new Date(s);
    return (
      d.getDate() +
      "/" +
      (d.getMonth() + 1) + // 0 indexed month
      "-" +
      d.getFullYear()
    );
  };

  handleLastLoginDate = s => {
    const d = new Date(s);
    return (
      d.getDate() +
      "/" +
      (d.getMonth() + 1) + // 0 indexed month
      "-" +
      d.getFullYear() +
      " kl. " +
      d.getHours() +
      "." +
      (d.getMinutes()).toString().padStart(2, '0')
    );
  };

  getDepartmentName= u=>{
    var z = userDepartments.find(x=>x.id===u.aspNetDepartmentId);
    return z==null?"Ingen":z.name
  }

  setDataTable(data) {
    return data.map(u => [
      <span style={{ paddingLeft: 20 }} />, // empty space
      <span>{u.userName}</span>,
      <span>{u.email}</span>,
      <span>
        {u.firstname} {u.lastname}
      </span>,
      <span>{this.getDepartmentName(u)}</span>,
      <span> {this.handleLastLoginDate(u.lastActivity)}</span>, // TODO for correct field
      // <span>{u.isActive == null ? "Not defined" : u.isActive === true ? "Aktiv" : "Inaktiv"}</span>,
      <ActionButtons item={u} />
    ]);
  }

  render() {
    const columns = [
      {
        name: "",
        options: {
          filter: false,
          sort: false
        }
      },
      {
        name: "Brugernavn",
        options: {
          filter: true,
          sort: true
        }
      },
      {
        name: "E-mail",
        options: {
          filter: true,
          sort: true
        }
      },
      {
        name: "Navn",
        options: {
          filter: false,
          sort: true
        }
      },
      {
        name: "Organisationer",
        options: {
          filter: true,
          sort: true
        }
      },
      {
        name: "Sidste login",
        options: {
          filter: false,
          sort: false
        }
      },
      {
        name: "",
        options: {
          filter: false,
          sort: false
        }
      }
    ];

    const options = {
      textLabels: {
        body: {
          noMatch: "",
          toolTip: "Sorter"
        },
        pagination: {
          next: "Næste side",
          previous: "Forrige side",
          rowsPerPage: "Brugere per side:",
          displayRows: "af"
        },
        toolbar: {
          filterTable: "Filtrer tabel"
        },
        selectedRows: {
          text: "valgt række(r)",
        },
        filter: {
          all: "All",
          title: "FILTRE",
          reset: "NULSTIL"
        }
      },
      filter: false,
      //       selectableRows: true,
      filterType: "checkbox",
      //        responsive: 'stacked',
      rowsPerPage: 15,
      print: false,
      download: false,
      viewColumns: false,
      search: false,
      selectableRows: false
    };

    if (this.props.usersReducer.users == null) return null;
    if (this.props.usersReducer.error != null)
      return (
        <Typography variant="caption">
          {this.props.usersReducer.error.toString()}
        </Typography>
      );

    if (this.props.usersReducer.users.length === 0) {
      return (
        <Typography variant="subtitle1" align="center">
          Ingen brugere fundet...
        </Typography>
      );
    }

    return (
      <>
        {this.props.usersReducer.loading ? <LinearProgress /> : undefined}
          <MUIDataTable
            data={this.setDataTable(this.props.usersReducer.users)}
            columns={columns}
            options={options}
        />
      </>
    );
  }
}

export default connect(state => state)(SortingSelecting);
